import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateReferee } from 'framework/api/methods'
import { useGetRefereesByCandidateReferee } from 'framework/api/methods/candidates/useGetRefereesByCandidateReferee'

import { MoreMenuButton, ReferenceTypeLabel } from 'components/atoms'
import { RowWithAlignment } from 'components/atoms/Row'

import { CandidateReferenceCheckSnack } from './CandidateReferenceCheckSnack'

type AccordionHeadingProps = {
  refereeIndex: number
  referee: CandidateReferee
  setSelectedRefereeDetails: any
  onViewReportClick: () => void
  refereeCount: any
  onEditClick: (() => void | undefined) | undefined
  setIsSendWebRefCheckReminderModalOpen: (
    value: React.SetStateAction<boolean>,
  ) => void
  onCallRecordingsClick: (() => void | undefined) | undefined
  setIsOpenRefereeDelete: (value: React.SetStateAction<boolean>) => void
}

const AccordionHeading = ({
  refereeIndex,
  referee,
  setSelectedRefereeDetails,
  onViewReportClick,
  refereeCount,
  onEditClick,
  setIsSendWebRefCheckReminderModalOpen,
  onCallRecordingsClick,
  setIsOpenRefereeDelete,
}: AccordionHeadingProps) => {
  const [searchParams] = useSearchParams()
  const VerticalLine = ({ className }: any) => (
    <div className={`h-[25px] border border-Gray-200 ${className}`}></div>
  )
  const candidateId = searchParams.get(`candidate_id`) ?? ''
  const refereeId = searchParams.get(`ref_id`) ?? ''
  const reportState = searchParams.get(`report_state`) ?? ''
  /**Fetch referee for the candidate */
  const { data: candidateReferee } = useGetRefereesByCandidateReferee(
    candidateId,
    refereeId,
  )

  useEffect(() => {
    if (reportState === 'true' && candidateReferee?.referee !== null) {
      setSelectedRefereeDetails(referee)
      onViewReportClick()
    }
  }, [candidateReferee])

  return (
    <RowWithAlignment className='w-full h-[40px] pb-1'>
      <RowWithAlignment justifyAlignment='start' className='w-full gap-3'>
        <span className='text-extra-small text-Gray-800 font-SemiBold bg-Gray-100 px-4 py-2 rounded-md min-w-[90px]'>
          REFEREE {refereeIndex.toString().padStart(2, '0')}
        </span>
        <VerticalLine className='mx-2' />
        {referee?.referenceType?.type && (
          <div className='flex gap-2 min-w-[240px]'>
            <span className='mr-1 text-extra-small leading-5 text-Gray-600'>
              Reference type:
            </span>
            <ReferenceTypeLabel
              type={referee?.referenceType?.type}
              className='text-extra-small'
            />
          </div>
        )}
        <VerticalLine className='mx-2' />
        <CandidateReferenceCheckSnack status={referee.status} />
      </RowWithAlignment>
      <RowWithAlignment justifyAlignment='end' className='w-[275px]'>
        <Button
          style={{ background: 'none', border: 'none', boxShadow: 'none' }}
          size='sm'
          textTheme='blue'
          theme='white'
          isDisabled={!referee?.refereeResponse}
          iconLeft={
            <i className='flex items-center ri-eye-line text-extra-small font-Medium' />
          }
          onClick={() => {
            setSelectedRefereeDetails(referee)
            onViewReportClick()
          }}>
          <span className='text-small leading-4 font-Medium'>
            Preview Report
          </span>
        </Button>
        <div className='h-[38px] w-[38px] mr-2'>
          <MoreMenuButton
            position={refereeIndex !== refereeCount ? 'bottom' : 'left'}
            actionButtons={[
              {
                iconClass: 'ri-pencil-line',
                onClick: () => {
                  onEditClick && onEditClick()
                },
                title: 'Edit Referee',
                status: 'normal',
              },
              {
                iconClass: 'ri-notification-4-line',
                onClick: () => {
                  setIsSendWebRefCheckReminderModalOpen(true)
                },
                title: 'Send Web Ref Check Reminder',
                status: 'normal',
                disabled: referee?.state !== 'AWAITING_RESPONSE',
              },
              {
                iconClass: 'ri-record-mail-line',
                onClick: () => {
                  onCallRecordingsClick && onCallRecordingsClick()
                },
                title: 'Call Recordings',
                status: 'normal',
              },
              {
                iconClass: 'ri-delete-bin-line',
                onClick: () => {
                  setIsOpenRefereeDelete(true)
                },
                title: 'Delete',
                status: 'danger',
              },
            ]}
            onClickMoreButton={() => {
              referee && setSelectedRefereeDetails(referee)
            }}
          />
        </div>
      </RowWithAlignment>
    </RowWithAlignment>
  )
}

export default AccordionHeading
