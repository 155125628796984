import { Radio, SimpleSelect, TextField } from '@labourhub/labour-hub-ds'

import { Rate } from 'components/atoms'
import { Card } from 'components/atoms/Card'
import { Checkbox } from 'components/core/Checkbox'
import { TextArea } from 'components/molecules/TextArea'
import { cn } from 'utils'
import { QuestionType } from 'utils/getQuestionType'

type AssesmentAnswerRatingViewProps = {
  selectedTemplate: any
  isPrint: boolean
  [x: string]: any
}

export const AssesmentAnswerRatingView = ({
  selectedTemplate,
  isPrint,
}: AssesmentAnswerRatingViewProps) => {
  const icon =
    selectedTemplate?.answerRating == 'Incorrect'
      ? 'ri-close-circle-fill'
      : selectedTemplate?.answerRating == 'NotAnswered'
      ? 'ri-close-line'
      : selectedTemplate?.answerRating == '5'
      ? 'ri-checkbox-circle-fill'
      : ''

  const iconColor =
    selectedTemplate?.answerRating == 'Incorrect'
      ? 'TerraCotta-800'
      : selectedTemplate?.answerRating == 'NotAnswered'
      ? 'Gray-400'
      : selectedTemplate?.answerRating == '5'
      ? 'Green-800'
      : '[#5B21B6]'

  const text =
    selectedTemplate?.answerRating == 'Incorrect'
      ? 'Incorrect'
      : selectedTemplate?.answerRating == 'NotAnswered'
      ? 'Not Answered'
      : selectedTemplate?.answerRating == '5'
      ? 'Correct'
      : 'Partially correct'

  const textColor =
    selectedTemplate?.answerRating == 'Incorrect'
      ? 'TerraCotta-800'
      : selectedTemplate?.answerRating == 'NotAnswered'
      ? 'Gray-800'
      : selectedTemplate?.answerRating == '5'
      ? 'Green-800'
      : '[#5B21B6]'

  const checkboxAnswerSelection =
    selectedTemplate.questionType === QuestionType.CheckBox &&
    selectedTemplate.answer &&
    JSON.parse(selectedTemplate.answer).length > 0
      ? JSON.parse(selectedTemplate.answer)
      : []
  return (
    <>
      <div className='flex flex-col w-full justify-start h-auto my-5'>
        {/* <Card> */}
        <div className='w-full'>
          {selectedTemplate.questionNumber !== 0 &&
          selectedTemplate.questionNumber !== null ? (
            <div className='flex justify-between w-full'>
              <div className=' px-2 py-1 mb-3 max-w-[180px] rounded-full text-[#1E40AF] font-Medium text-extra-small bg-[#DBEAFE]'>
                <span>{`Question No ${
                  selectedTemplate.questionNumber < 10
                    ? `0${selectedTemplate.questionNumber}`
                    : selectedTemplate.questionNumber
                }`}</span>
              </div>
              <div className=' px-2 py-1 max-w-[180px] font-Medium text-extra-small'>
                <div className='flex gap-1 items-center'>
                  <i className={`${icon} text-small text-${iconColor}`} />
                  <span className={`text-${textColor}`}>{text}</span>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <p
            className={cn('w-full text-Gray-800 whitespace-pre-wrap my-4', {
              'text-[1.2rem] font-[700]':
                selectedTemplate.questionType === QuestionType.Heading,
              'font-Medium':
                selectedTemplate.questionType != QuestionType.Heading,
              'whitespace-pre-line':
                selectedTemplate.questionType == QuestionType.Paragraph,
              "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
                selectedTemplate.isRequired,
            })}>
            {selectedTemplate.questionNumber !== 0 &&
            selectedTemplate.questionNumber !== null
              ? selectedTemplate.questionNumber + '. '
              : ''}
            {selectedTemplate.question}
          </p>
          <div className='border-[0.5px] border-[#E5E7EB] my-3' />

          <div className='w-full flex space-x-3 justify-evenly items-center pointer-events-auto'>
            <div className={`w-full`}>
              {selectedTemplate.questionType === QuestionType.Text &&
                selectedTemplate.answer &&
                selectedTemplate.answer.length > 65 && (
                  <TextArea
                    className='w-full'
                    value={selectedTemplate.answer}
                    isReadonly={true}
                    placeholder=''
                    textAreaClassName='h-auto'
                  />
                )}
              {selectedTemplate.questionType === QuestionType.Text &&
                selectedTemplate.answer &&
                selectedTemplate.answer.length <= 65 && (
                  <TextField
                    className='w-full'
                    isDisabled={true}
                    value={selectedTemplate.answer}
                    placeholder=''
                  />
                )}
              {selectedTemplate.questionType === QuestionType.Text &&
                !selectedTemplate.answer && (
                  <TextField
                    className='w-full'
                    isDisabled={true}
                    value={selectedTemplate.answer}
                    placeholder=''
                  />
                )}

              {selectedTemplate.questionType === QuestionType.TextArea && (
                <TextArea
                  className='w-full'
                  value={selectedTemplate.answer}
                  isReadonly={true}
                  placeholder=''
                  rows={4}
                  textAreaClassName={`${isPrint ? 'h-auto' : '!h-[150px]'}`}
                />
              )}

              {selectedTemplate.questionType === QuestionType.Rating && (
                <Rate
                  readonly={true}
                  rating={selectedTemplate.answer || '0'}
                  count={Number(selectedTemplate.ratingCount || '5')}
                />
              )}
              {selectedTemplate.questionType === QuestionType.CheckBox && (
                <div className='flex flex-col gap-y-2 pointer-events-none'>
                  {JSON.parse(selectedTemplate.answerDetails).map(
                    (item, idx) => (
                      <Checkbox
                        key={idx}
                        id={`custom-checkbox-0${idx}`}
                        labelText={item}
                        checked={!!checkboxAnswerSelection.includes(item)}
                        labelTextClassName='font-Regular'
                        checkboxClassName='ml-0'
                      />
                    ),
                  )}
                </div>
              )}

              {selectedTemplate.questionType === QuestionType.Select && (
                <SimpleSelect
                  className='w-full'
                  value={{
                    value: selectedTemplate.answer,
                    label: selectedTemplate.answer,
                  }}
                  placeholder='Select Option'
                />
              )}

              {selectedTemplate.questionType === QuestionType.Radio && (
                <div className='flex gap-6'>
                  <Radio
                    id={`StatusYes_${selectedTemplate.questionID}`}
                    checked={
                      selectedTemplate.answer === 'Yes' ||
                      selectedTemplate.answer === 'True'
                    }
                    className='cursor-pointer'
                    name={`YesNoStatus_${selectedTemplate.questionID}`}
                    helperText=''
                    labelText=' Yes'
                  />

                  <Radio
                    className='cursor-pointer'
                    id={`StatusYes_${selectedTemplate.questionID}`}
                    checked={
                      selectedTemplate.answer === 'No' ||
                      selectedTemplate.answer === 'False'
                    }
                    name={`YesNoStatus_${selectedTemplate.questionID}`}
                    helperText=''
                    labelText=' No'
                  />
                </div>
              )}

              {(selectedTemplate.questionType ===
                QuestionType.RadioPositiveDetail ||
                selectedTemplate.questionType ===
                  QuestionType.RadioNegativeDetail) && (
                <div className='flex flex-col w-full'>
                  <div className='flex gap-6'>
                    <Radio
                      checked={
                        selectedTemplate.answer === 'Yes' ||
                        selectedTemplate.answer === 'True'
                      }
                      id={`${selectedTemplate.questionID}-detailed-yes`}
                      className='cursor-pointer select-none'
                      name={`00${selectedTemplate.questionID}-detailed`}
                      helperText=''
                      labelText='Yes'
                    />

                    <Radio
                      checked={
                        selectedTemplate.answer === 'No' ||
                        selectedTemplate.answer === 'False'
                      }
                      className='cursor-pointer select-none'
                      id={`${selectedTemplate.questionID}-detailed-yes`}
                      name={`00${selectedTemplate.questionID}-detailed`}
                      helperText=''
                      labelText='No'
                    />
                  </div>

                  <TextArea
                    className='w-full'
                    value={selectedTemplate.answerDescription}
                    isReadonly={true}
                    placeholder=''
                    rows={4}
                    textAreaClassName='h-auto'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {selectedTemplate.questionNumber !== 0 ? (
          <Card className='mt-5 bg-[#F9FAFB]'>
            <div className='w-full flex flex-col gap-8'>
              <div>
                <p className='w-full text-Gray-800 font-SemiBold whitespace-pre-wrap'>
                  Rate Answer
                </p>

                <div className='w-full space-x-3 items-center flex gap-3'>
                  <Rate
                    rating={
                      selectedTemplate?.answerRating == 'Incorrect' ||
                      selectedTemplate?.answerRating == 'NotAnswered'
                        ? 0
                        : selectedTemplate?.answerRating
                    }
                    readonly
                    count={5}
                  />
                  {selectedTemplate?.isAiGenerated ? (
                    <li className='text-Gray-800'>Ai Generated</li>
                  ) : (
                    <li className='text-[#3730A3]'>Updated Manually</li>
                  )}
                </div>
              </div>
              {selectedTemplate?.comment && (
                <div>
                  <p className='w-full text-Gray-800 font-SemiBold whitespace-pre-wrap'>
                    Comment
                  </p>

                  <div className='w-full space-x-3 items-center'>
                    <li className='text-Gray-500'>
                      {selectedTemplate?.comment}
                    </li>
                  </div>
                </div>
              )}
            </div>
          </Card>
        ) : null}
      </div>
    </>
  )
}
