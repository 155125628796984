import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentReportViewById } from 'api/assessment/getAssessmentReportViewById'
import {
  AssessmentResponseListDto,
  useGetAssessmentResponsesList,
} from 'api/assessment/getAssessmentResponsesList'
import CN from 'classnames'
import {
  assessmentEmailStatusOptions,
  assessmentResponseStatusOptions,
} from 'features/candidates/data/assessmentResponseStatusOptions'
import { AssessmentPreviewModal } from 'features/questionnaires/components/AssessmentTab/AssessmentTable'
import { AnimatePresence, motion } from 'framer-motion'
import { useGetManagingConsultantList } from 'framework/api/methods'

import { TableHeader, UITable } from 'components/common/UITable'
import { AssesmentPreviewModalReport } from 'components/molecules/AssesmentPreviewModal'
import { AssessmentDownloadModal } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentDownloadModal'

import { CancelRequestModal } from './CancelRequest/CancelRequestModal'
import { AssessmentResponsesListColumn } from './columns'
import { SendAssessmentReminderModal } from './SendAssessmentReminderModal'

type AssessmentResultProps = {
  candidateId: string
  candidateDetails: any
}

const AssessmentResult = ({
  candidateId,
  candidateDetails,
}: AssessmentResultProps) => {
  const notify = (props: any) => Toast(props)
  const [markingValue, setMarkingValue] = useState<any>({
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  })
  const [reportViewData, setReportViewData] = useState<any>(null)

  // Table States & Variables
  const [tableData, setTableData] = useState<AssessmentResponseListDto>({
    skip: 0,
    take: 0,
    assessmentResponses: [],
    totalCount: 0,
    message: '-',
  })
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData?.skip || 0,
    take: tableData?.take || 20,
    search: '',
    sortColumnName: 'SentOn',
    sortDirection: 'desc',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)

  const [selectedStatus, setSelectedStatus] = useState<any>({
    label: '',
    value: '',
  })
  const [selectedEmailStatus, setSelectedEmailStatus] = useState<any>({
    label: '',
    value: '',
  })

  const [selectedManagingConsultant, setSelectedManagingConsultant] =
    useState<any>({
      id: '',
      name: '',
    })

  const [filterVariables, setFilterVariables] = useState<any>({
    status: '',
    managingConsultant: '',
  })

  const [selectedResponse, setSelectedResponse] = useState<any>()
  const [responseId, setResponseId] = useState(null)

  const [isDownlaodAssessmentReport, setIsDownlaodAssessmentReport] =
    useState(false)

  const [viewAssessmentModalReport, setViewAssessmentModalReport] =
    useState(false)

  const [viewAssessmentModal, setViewAssessmentModal] = useState(false)

  const [isReminderModalActive, setIsReminderModalActive] = useState(false)

  const [isCancelRequestModalActive, setIsCancelRequestModalActive] =
    useState(false)

  const [searchParams] = useSearchParams()
  const assessmentId = searchParams.get('assessment_id')

  useEffect(() => {
    if (assessmentId) {
      setSelectedResponse({ assessmentId })
      setViewAssessmentModalReport(true)
    }
  }, [assessmentId])

  const { data: managingConsultantList } = useGetManagingConsultantList()

  const { refetch: getAssessmentReportView } = useGetAssessmentReportViewById(
    {
      candidateId: candidateId,
      assessmentId: selectedResponse?.assessmentId, // Ensure assessmentId is present
      responseId: responseId!,
    },
    async (data: any) => {
      setReportViewData(data?.assessmentReport)
      setMarkingValue({
        correct: data?.assessmentReport?.assessmentAutoMarking?.correct,
        partiallyCorrect:
          data?.assessmentReport?.assessmentAutoMarking?.partiallyCorrect,
        incorrect: data?.assessmentReport?.assessmentAutoMarking?.incorrect,
        notAnswered: data?.assessmentReport?.assessmentAutoMarking?.notAnswered,
      })
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  // Fetch assessment list
  const {
    refetch: refreshAssessmentList,
    isLoading: getAssessmentListIsLoading,
    isFetching: getAssessmentListIsFetching,
  } = useGetAssessmentResponsesList(
    {
      candidateId: candidateId,
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      status: selectedStatus?.value || '',
      emailStatus: selectedEmailStatus?.value || '',
      managingConsultant: selectedManagingConsultant?.managingConsultant || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
      ManagingConsultantId: selectedManagingConsultant?.id,
    },
    (data: any) => {
      setTableData(data || {})
      setIsDownlaodAssessmentReport(false)
    },
    ({ response: { data: errData } }: any) => {
      notify({
        alertHeader: errData.messageTitle ? errData.messageTitle : '',
        alertBody: errData.message ? errData.message : 'Error!',
        status: 'Error',
      })
    },
  )

  useEffect(() => {
    refreshAssessmentList()
  }, [paginationVariables, filterVariables])

  useEffect(() => {
    // Only trigger if selectedResponse is not null and has assessmentId
    if (
      selectedResponse &&
      selectedResponse.assessmentId &&
      (viewAssessmentModalReport || isDownlaodAssessmentReport)
    ) {
      getAssessmentReportView()
    }
  }, [selectedResponse, viewAssessmentModalReport, isDownlaodAssessmentReport])

  const tableColumns = useMemo(() => {
    if (tableData?.assessmentResponses?.length > 0) {
      const columns: any = AssessmentResponsesListColumn(
        (res) => {
          setResponseId(res.id)
          setSelectedResponse(res)
        },
        setIsReminderModalActive,
        setViewAssessmentModal,
        setViewAssessmentModalReport,
        setIsDownlaodAssessmentReport,
        setIsCancelRequestModalActive,
      )
      return columns
    }
    return []
  }, [tableData])

  const applyFilter = () => {
    setFilterActive(true)
    refreshAssessmentList()
    setIsFilterMenuOpen(false)
    setFilterVariables({
      status:
        selectedStatus?.value === 'Awaiting'
          ? 'Awaiting'
          : selectedStatus?.value === 'Received'
          ? 'Received'
          : selectedStatus?.value === 'Declined'
          ? 'Declined'
          : '',
      emailStatus:
        selectedEmailStatus?.value === 'Sent'
          ? 'Sent'
          : selectedEmailStatus?.value === 'Opened'
          ? 'Opened'
          : '',
      managingConsultant: selectedManagingConsultant?.id || '',
    })
  }

  const resetFilter = () => {
    setSelectedStatus('')
    setSelectedEmailStatus('')
    setSelectedManagingConsultant('')
    setFilterActive(false)
    setIsFilterMenuOpen(false)

    setFilterVariables({
      status: '',
      managingConsultant: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  useEffect(() => {
    setFilterVariables({
      status: '',
      managingConsultant: '',
    })
  }, [])

  useEffect(() => {
    assessmentResponseStatusOptions?.find((item) => {
      if (item?.label === '') {
        setSelectedStatus({
          ...selectedStatus,
          label: item?.label,
          value: item?.value,
        })
      }
    })
  }, [assessmentResponseStatusOptions])

  useEffect(() => {
    assessmentEmailStatusOptions?.find((item) => {
      if (item?.label === '') {
        setSelectedEmailStatus({
          ...selectedEmailStatus,
          label: item?.label,
          value: item?.value,
        })
      }
    })
  }, [assessmentEmailStatusOptions])

  const handleCloseModals = () => {
    setIsDownlaodAssessmentReport(false)
  }

  return (
    <div>
      {tableData.assessmentResponses.length > 0 && tableData.message && (
        <div
          className={CN(
            'rounded-[8px] p-4 h-[50px] border-[1px] focus:outline-none flex items-center w-full bg-Blue-50 border-Blue-200 focus:outline-1 mb-5',
          )}>
          <span>
            <i className='ri-information-fill text-[#027EAB] text-base' />
          </span>
          <span
            className={CN(
              'flex w-max flex-row items-center px-2.5 text-small leading-3 font-Medium text-[#027EAB]',
            )}>
            {tableData.message}
          </span>
        </div>
      )}

      <SendAssessmentReminderModal
        isModalActive={isReminderModalActive}
        setIsModalActive={setIsReminderModalActive}
        candidateId={candidateId}
        selectedResponse={selectedResponse}
        refreshAssessmentList={refreshAssessmentList}
        responseId={responseId ? responseId : ''}
      />
      {responseId && viewAssessmentModalReport && (
        <AssesmentPreviewModalReport
          isModalActive={viewAssessmentModalReport}
          setIsModalActive={setViewAssessmentModalReport}
          templateId={selectedResponse.assessmentId}
          candidateDetails={candidateDetails}
          refreshAssessmentList={refreshAssessmentList}
          responseId={responseId}
        />
      )}
      {viewAssessmentModal && (
        <AssessmentPreviewModal
          isModalActive={viewAssessmentModal}
          setIsModalActive={setViewAssessmentModal}
          templateId={selectedResponse.assessmentId}
          templateName={selectedResponse.assessmentName}
          isDraft={false}
          isPreview={true}
        />
      )}

      <div className='flex flex-col w-full px-4 bg-white border rounded-t-lg outline-none border-Gray-100'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          searchFieldPlaceholder={'Search assessment name'}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}
        />
        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex w-full bg-white'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              <div className='flex items-center w-full px-4 pt-3 pb-4 z-[4]'>
                <SimpleSelect
                  label='Response Status'
                  value={assessmentResponseStatusOptions?.filter(
                    (item: any) => item?.value === selectedStatus?.value,
                  )}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedStatus(selectedItem)
                  }}
                  placeholder=''
                  options={assessmentResponseStatusOptions}
                />

                <SimpleSelect
                  label='Email Status'
                  value={assessmentEmailStatusOptions?.filter(
                    (item: any) => item?.value === selectedEmailStatus?.value,
                  )}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedEmailStatus(selectedItem)
                  }}
                  placeholder=''
                  options={assessmentEmailStatusOptions}
                />

                <SimpleSelect
                  label='Sent By'
                  placeholder=''
                  value={
                    selectedManagingConsultant?.name
                      ? {
                          label: selectedManagingConsultant?.name,
                          value: selectedManagingConsultant?.id,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedManagingConsultant({
                      ...selectedManagingConsultant,
                      id: selectedItem?.value,
                      name: selectedItem.label,
                    })
                  }}
                  options={managingConsultantList}
                />

                <Button
                  className='mr-4 h-[38px] w-[139px] mt-5'
                  isDisabled={
                    !selectedManagingConsultant?.name &&
                    !selectedStatus?.value &&
                    !selectedEmailStatus?.value
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px] mt-5'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className='w-full h-[535px] relative '>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.assessmentResponses || []}
            isLoading={
              getAssessmentListIsLoading || getAssessmentListIsFetching
            }
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
      {isDownlaodAssessmentReport && reportViewData && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={isDownlaodAssessmentReport}
          setIsModalActive={setIsDownlaodAssessmentReport}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
        />
      )}
      <CancelRequestModal
        isOpen={isCancelRequestModalActive}
        setIsOpen={setIsCancelRequestModalActive}
        candidateId={candidateId}
        selectedResponse={selectedResponse}
        refreshAssessmentList={refreshAssessmentList}
      />
    </div>
  )
}

export default AssessmentResult
