import { useEffect, useRef, useState } from 'react'
import { Badge } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { Card } from 'components/atoms/Card'

type CandidateStageProps = {
  reportViewData: any
  onChange: (stage: string) => void
}

const CandidateStage = ({ reportViewData, onChange }: CandidateStageProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const status = reportViewData.candidateStage
  const [badgeText, setBadgeText] = useState(
    status === 'RESPONSE_RECEVIED'
      ? 'Received'
      : status === 'AWAITING_RESPONSE'
      ? 'Awaiting'
      : status === 'RESPONSE_IN_REVIEW'
      ? 'In Review'
      : status === 'RESPONSE_EVALUATED'
      ? 'Evaluated'
      : status === 'REQUEST_DECLINED'
      ? 'Declined'
      : 'Not Sent',
  )
  const handleTheme = (status) => {
    switch (status) {
      case 'RESPONSE_RECEVIED':
        return 'green'
      case 'AWAITING_RESPONSE':
        return 'yellow'
      case 'RESPONSE_IN_REVIEW':
        return 'yellow'
      case 'RESPONSE_EVALUATED':
        return 'blue'
      case 'REQUEST_DECLINED':
        return 'red'
      default:
        return 'gray'
    }
  }

  const [badgeTheme, setBadgeTheme] = useState(
    status === 'RESPONSE_RECEVIED'
      ? 'green'
      : status === 'AWAITING_RESPONSE'
      ? 'yellow'
      : status === 'RESPONSE_IN_REVIEW'
      ? 'yellow'
      : status === 'RESPONSE_EVALUATED'
      ? 'blue'
      : status === 'REQUEST_DECLINED'
      ? 'red'
      : 'gray',
  )

  const handleButtonClick = (text: string, stage: string) => {
    setBadgeText(text)
    setBadgeTheme(handleTheme(stage))
    setDropdownVisible(false)
    onChange(stage)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setDropdownVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Card className='relative h-[35%]'>
      <div className='flex items-center justify-between'>
        <div className='text-heading-4 font-SemiBold'>Candidate Stage</div>

        <div className='flex gap-3 relative'>
          <Badge size='large' theme={badgeTheme} className='w-20'>
            <div className='text-extra-small font-Medium leading-3'>
              {badgeText}
            </div>
          </Badge>
          <div>
            <button
              ref={buttonRef}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              className='focus:outline-none'>
              <i
                className={CN(
                  'text-[16px] ri-pencil-line mr-1',
                  dropdownVisible ? 'text-[#09121F]' : 'text-Cobalt-500',
                )}
              />
            </button>
            {dropdownVisible && (
              <div
                ref={dropdownRef}
                className={CN(
                  'absolute z-40 bg-white shadow-md border-[2px] border-Gray-100 rounded-md mt-1 select-none',
                  'w-[120px]',
                  'left-0',
                )}
                style={{
                  top: buttonRef.current?.offsetHeight || 0,
                }}>
                <button
                  className='block py-2 px-3 cursor-pointer select-none text-black hover:bg-Gray-100 w-full text-left'
                  onClick={() =>
                    handleButtonClick('Received', 'RESPONSE_RECEVIED')
                  }>
                  Received
                </button>
                <button
                  className='block py-2 px-3 cursor-pointer select-none text-black hover:bg-Gray-100 w-full text-left'
                  onClick={() =>
                    handleButtonClick('In Review', 'RESPONSE_IN_REVIEW')
                  }>
                  In Review
                </button>
                <button
                  className='block py-2 px-3 cursor-pointer select-none text-black hover:bg-Gray-100 w-full text-left'
                  onClick={() =>
                    handleButtonClick('Evaluated', 'RESPONSE_EVALUATED')
                  }>
                  Evaluated
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CandidateStage
