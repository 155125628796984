import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

export type UpdateSmsTemplateProps = {
  startDate: string | undefined
  endDate: string | undefined
  totalRefereeCount: number | undefined
}

type EditSmsTemplateDetailsProps = {
  id: string
  updatedSmsTemplateDetails: UpdateSmsTemplateProps
}

export const useEditSmsTemplateDetails = () => {
  const mutate = useMutation(
    ({ id, updatedSmsTemplateDetails }: EditSmsTemplateDetailsProps) => {
      return putAPI(`/SMS/Templates/${id}`, updatedSmsTemplateDetails)
    },
  )

  return mutate
}
