import React, { useMemo, useRef } from 'react'
import { useDroppable } from '@dnd-kit/core'
import {
  SortableContext,
  SortableData,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { motion } from 'framer-motion'

import { cn } from 'utils'

import { AssessmentPreviewQuestionCard } from './AssessmentPreviewQuestionCard'

type AssessmentPreviewQuestionCardsColumnProps = {
  items: AssessmentQuestion[]
}

export const AssessmentPreviewQuestionCardsColumn = ({
  items,
}: AssessmentPreviewQuestionCardsColumnProps) => {
  const { setNodeRef, isOver, active, over } = useDroppable({
    id: 'question-cards',
  })

  const bottomRef = useRef<HTMLDivElement>(null)

  const isQuestionTypeDrag = useMemo(
    () =>
      (active?.data.current as SortableData)?.sortable.containerId ==
      'question-types',
    [active],
  )

  const filteredItems = useMemo(
    () =>
      items.filter(
        (item) => item.type !== 'heading' && item.type !== 'paragraph',
      ),
    [items],
  )

  const placeHolderIndex = useMemo(() => {
    const overIndex = (over?.data.current as SortableData)?.sortable.index
    return isQuestionTypeDrag ? overIndex : null
  }, [isQuestionTypeDrag, over])

  return (
    <SortableContext
      id='question-cards'
      items={items}
      strategy={verticalListSortingStrategy}>
      <motion.div
        ref={setNodeRef}
        className={cn(
          'flex flex-col flex-shrink flex-grow p-5 bg-Gray-100 space-y-3',
          { 'pb-[60px]': isOver },
        )}
        layoutId='question-cards'
        transition={{
          duration: !isOver ? 0.25 : 0,
          easings: {
            type: 'spring',
          },
          zIndex: {
            delay: isOver ? 0 : 0.25,
          },
        }}>
        <>
          {items.map((item, index) => {
            const filteredIndex = filteredItems.findIndex(
              (fi) => fi.id === item.id,
            )
            const key = `question-card-${item.id}`

            return (
              <React.Fragment key={key}>
                {placeHolderIndex === index && (
                  <div className='h-[60px] w-full' />
                )}
                <AssessmentPreviewQuestionCard
                  question={item}
                  index={index}
                  filteredIndex={filteredIndex}
                />
              </React.Fragment>
            )
          })}
        </>

        <div ref={bottomRef} />
      </motion.div>
    </SortableContext>
  )
}
