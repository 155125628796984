import { useEffect, useRef } from 'react'
import {
  Badge,
  Button,
  Checkbox,
  Radio,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'

import { PageLoader, Rate } from 'components/atoms'
import { Card } from 'components/atoms/Card'
import { TextArea } from 'components/molecules/TextArea'
import { cn } from 'utils'
import { QuestionType } from 'utils/getQuestionType'
import { updateNode } from 'utils/updateNode'

import { Modal, ModalFooter } from '../../Modal'

import { AnswerPreview } from './AnswerPreview'

type AssesmentAnswerRatingModalProps = {
  selectedTemplate: any
  setJsonSchema: React.Dispatch<any>
  jsonSchema: any
  setSelectedTemplate: React.Dispatch<any>
  setQuestionData: React.Dispatch<any>
  questionData: any
  fetchRealtimeRatingData: (updatedJsonSchema: any) => void
  isRealtimeAssessmentRatingLoading: boolean
  [x: string]: any
}

export const AssesmentAnswerRatingModal = ({
  isActive,
  modalClose,
  selectedTemplate,
  templateId,
  setJsonSchema,
  jsonSchema,
  setSelectedTemplate,
  setQuestionData,
  questionData,
  fetchRealtimeRatingData,
  isRealtimeAssessmentRatingLoading,
}: AssesmentAnswerRatingModalProps) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const jsonSchemaParsed = JSON.parse(jsonSchema)
  useEffect(() => {
    updateNode(componentRef)
  }, [isActive, templateId])

  const handleConfirm = async () => {
    const schemaItem = jsonSchemaParsed.properties[selectedTemplate.questionID]

    if (schemaItem) {
      // Ensure properties object exists
      schemaItem.properties = schemaItem.properties || {}

      // Update or add `comment`
      if (schemaItem.properties.comment) {
        schemaItem.properties.comment.description = selectedTemplate.comment
      } else {
        schemaItem.properties.comment = {
          type: 'string',
          description: selectedTemplate.comment,
        }
      }

      // Update or add `isAIGenerated`
      if (schemaItem.properties.isAIGenerated) {
        schemaItem.properties.isAIGenerated.description = 'False'
      } else {
        schemaItem.properties.isAIGenerated = {
          type: 'boolean',
          description: 'False',
        }
      }

      // Update or add `rating`
      if (schemaItem.properties.rate) {
        schemaItem.properties.rate.description =
          selectedTemplate.answerRating.toString()
      } else {
        schemaItem.properties.rate = {
          type: 'number',
          description: selectedTemplate.answerRating.toString(),
        }
      }
    }

    const updatedJsonSchemaString = JSON.stringify(jsonSchemaParsed)
    setJsonSchema(updatedJsonSchemaString)
    const updatedTemplate = {
      ...selectedTemplate,
      isAiGenerated: false,
    }
    setSelectedTemplate(updatedTemplate)
    const updatedAssessment = questionData.map((item) =>
      item.questionID === updatedTemplate.questionID
        ? { ...item, ...updatedTemplate }
        : item,
    )
    setQuestionData(updatedAssessment)
    await fetchRealtimeRatingData(updatedJsonSchemaString)
    !isRealtimeAssessmentRatingLoading && modalClose()
  }

  const checkboxAnswerSelection =
    selectedTemplate.questionType === QuestionType.CheckBox &&
    selectedTemplate.answer &&
    JSON.parse(selectedTemplate.answer).length > 0
      ? JSON.parse(selectedTemplate.answer)
      : []

  return (
    <>
      <Modal
        headerTitle={`Assessment`}
        customWidth='1125px'
        isActive={isActive}
        isFooterShow={false}
        size='lg'
        onClickSecondaryBtn={modalClose}
        onHeaderCloseButtonClick={modalClose}
        onOverlayClick={modalClose}
        secondaryButtonTitle='Close'>
        <div className='flex flex-col w-full justify-start h-[720px] overflow-y-auto bg-[#F9FAFB]'>
          <div className='px-8 my-2 w-full flex justify-between gap-4'>
            {isRealtimeAssessmentRatingLoading && <PageLoader size='xxs' />}
            <Card>
              <div className='flex gap-8 flex-row'>
                <div className='w-[50%]'>
                  <div className='flex justify-between w-full'>
                    <div className=' px-2 py-1 max-w-[180px] border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
                      <span>{`Question ${
                        selectedTemplate.questionNumber < 10
                          ? `0${selectedTemplate.questionNumber}`
                          : selectedTemplate.questionNumber
                      }`}</span>
                    </div>
                  </div>

                  <p
                    className={cn(
                      'w-full text-Gray-800 whitespace-pre-wrap my-4',
                      {
                        'text-[1.2rem] font-[700]':
                          selectedTemplate.questionType ===
                          QuestionType.Heading,
                        'font-Medium':
                          selectedTemplate.questionType != QuestionType.Heading,
                        'whitespace-pre-line':
                          selectedTemplate.questionType ==
                          QuestionType.Paragraph,
                        "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
                          selectedTemplate.isRequired,
                      },
                    )}>
                    {selectedTemplate.question}
                  </p>

                  <div className='w-full flex space-x-3 justify-evenly items-center pointer-events-auto'>
                    <div className={`w-full`}>
                      {selectedTemplate.questionType === QuestionType.Text &&
                        selectedTemplate.answer &&
                        selectedTemplate.answer.length > 65 && (
                          <TextArea
                            className='w-full'
                            textAreaClassName='h-auto'
                            rows={2}
                            value={selectedTemplate.answer}
                            isReadonly={true}
                            placeholder=''
                          />
                        )}
                      {selectedTemplate.questionType === QuestionType.Text &&
                        selectedTemplate.answer &&
                        selectedTemplate.answer.length <= 65 && (
                          <TextField
                            className='w-full'
                            isDisabled={true}
                            value={selectedTemplate.answer}
                            placeholder=''
                          />
                        )}

                      {selectedTemplate.questionType === QuestionType.Text &&
                        !selectedTemplate.answer && (
                          <TextField
                            className='w-full'
                            isDisabled={true}
                            value={selectedTemplate.answer}
                            placeholder=''
                          />
                        )}

                      {selectedTemplate.questionType ===
                        QuestionType.TextArea && (
                        <TextArea
                          className='w-full'
                          rows={4}
                          textAreaClassName='!h-[150px]'
                          value={selectedTemplate.answer}
                          isReadonly={true}
                          placeholder=''
                        />
                      )}

                      {selectedTemplate.questionType ===
                        QuestionType.Rating && (
                        <Rate
                          readonly={true}
                          rating={selectedTemplate.answer || '0'}
                          count={Number(selectedTemplate.ratingCount || '5')}
                        />
                      )}

                      {selectedTemplate.questionType ===
                        QuestionType.CheckBox && (
                        <div className='flex flex-col gap-y-2 pointer-events-none'>
                          {JSON.parse(selectedTemplate.answerDetails).map(
                            (item, idx) => (
                              <Checkbox
                                key={idx}
                                id={`custom-checkbox-0${idx}`}
                                labelText={item}
                                checked={
                                  !!checkboxAnswerSelection.includes(item)
                                }
                                labelTextClassName='font-Regular'
                                checkboxClassName='ml-0'
                              />
                            ),
                          )}
                        </div>
                      )}

                      {selectedTemplate.questionType ===
                        QuestionType.Select && (
                        <SimpleSelect
                          className='w-full pointer-events-none'
                          value={{
                            value: selectedTemplate.answer,
                            label: selectedTemplate.answer,
                          }}
                          options={JSON.parse(
                            selectedTemplate.answerDetails,
                          ).map((option) => ({
                            value: option,
                            label: option,
                          }))}
                          placeholder='Select Option'
                        />
                      )}

                      {selectedTemplate.questionType === QuestionType.Radio && (
                        <div className='flex gap-6 pointer-events-none'>
                          <Radio
                            id='StatusYes'
                            checked={
                              selectedTemplate.answer === 'Yes' ||
                              selectedTemplate.answer === 'True'
                            }
                            className='cursor-pointer'
                            name='YesNoStatus'
                            helperText=''
                            labelText=' Yes'
                          />

                          <Radio
                            className='cursor-pointer'
                            id='StatusNo'
                            checked={
                              selectedTemplate.answer === 'No' ||
                              selectedTemplate.answer === 'False'
                            }
                            name='YesNoStatus'
                            helperText=''
                            labelText=' No'
                          />
                        </div>
                      )}

                      {(selectedTemplate.questionType ===
                        QuestionType.RadioPositiveDetail ||
                        selectedTemplate.questionType ===
                          QuestionType.RadioNegativeDetail) && (
                        <div className='flex flex-col w-full'>
                          <div className='flex gap-6 pointer-events-none'>
                            <Radio
                              checked={
                                selectedTemplate.answer === 'Yes' ||
                                selectedTemplate.answer === 'True'
                              }
                              id={`${selectedTemplate.id}-detailed-yes`}
                              className='cursor-pointer select-none'
                              name={`00${selectedTemplate.id}-detailed`}
                              helperText=''
                              labelText='Yes'
                            />

                            <Radio
                              checked={
                                selectedTemplate.answer === 'No' ||
                                selectedTemplate.answer === 'False'
                              }
                              className='cursor-pointer select-none'
                              id={`${selectedTemplate.id}-detailed-yes`}
                              name={`00${selectedTemplate.id}-detailed`}
                              helperText=''
                              labelText='No'
                            />
                          </div>

                          <TextArea
                            className='w-full mt-3'
                            isReadonly={true}
                            label='Explain'
                            value={selectedTemplate.answerDetails}
                            placeholder=''
                            rows={4}
                            textAreaClassName='!h-[150px]'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='w-[50%] flex flex-col gap-3'>
                  <div>
                    <p className='w-full text-Gray-800 font-SemiBold whitespace-pre-wrap'>
                      Rate Answer
                    </p>

                    <div className='w-full space-x-3 items-center'>
                      {selectedTemplate.answerRating === 'NotAnswered' ? (
                        <Badge theme={'yellow'}>Not Answered</Badge>
                      ) : (
                        <Rate
                          rating={
                            selectedTemplate?.answerRating === 'Incorrect'
                              ? '0'
                              : selectedTemplate?.answerRating
                          }
                          onRating={(rate) => {
                            setSelectedTemplate({
                              ...selectedTemplate,
                              answerRating: rate.toString(),
                            })
                          }}
                          count={5}
                          defaultRating={0}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <p className='w-full text-Gray-800 font-SemiBold whitespace-pre-wrap my-4'>
                      Comment
                    </p>

                    <div className='w-full space-x-3 items-center'>
                      <TextArea
                        className='w-full'
                        value={selectedTemplate?.comment}
                        placeholder='Something about myself.'
                        rows={5}
                        onChange={(e: any) => {
                          setSelectedTemplate({
                            ...selectedTemplate,
                            comment: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className='px-8 my-2 w-full'>
            <div className='w-full bg-white'>
              <AnswerPreview selectedTemplate={selectedTemplate} />
            </div>
          </div>
        </div>

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={() => modalClose()} textTheme='blue' theme='white'>
              Cancel
            </Button>
            <Button
              onClick={() => handleConfirm()}
              className='ml-2'
              theme='cobalt'>
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
