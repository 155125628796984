import React, { useState } from 'react'

import { Card } from 'components/atoms/Card'

import { CreditListItem } from './CreditListItem'

type AntiCheatinMonitorProps = {
  reportViewData: any
  isReport: boolean
  setIsWebcamSnapsView?: React.Dispatch<React.SetStateAction<boolean>>
}

const AntiCheatinMonitor = ({
  reportViewData = [],
  isReport,
  setIsWebcamSnapsView,
}: AntiCheatinMonitorProps) => {
  const images = reportViewData.antiCheatingImageUrl
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loading, setLoading] = useState(true)

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    )
    setLoading(true)
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    )
    setLoading(true)
  }

  const progressBarPercentage = ((currentIndex + 1) / images.length) * 100
  return (
    <>
      <Card>
        <div className='flex items-center justify-between text-heading-4 font-SemiBold pb-4'>
          Anti-Cheating Monitor
        </div>
        {images.length > 0 && !isReport && (
          <div className='w-full mx-auto'>
            <div className='relative'>
              {loading && (
                <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10'>
                  <div className='w-12 h-12 border-4 border-Blue-500 border-t-transparent border-solid rounded-full animate-pulse200'></div>
                </div>
              )}

              <img
                src={images[currentIndex].imageUrl}
                alt={`Slide ${currentIndex + 1}`}
                className={`w-full h-64 object-cover rounded-lg ${
                  loading ? 'opacity-0' : 'opacity-100'
                }`}
                onLoad={() => setLoading(false)}
              />
              <button
                onClick={handlePrev}
                className={`${
                  images.length === 1 && 'hidden'
                } absolute top-1/2 left-4 transform -translate-y-1/2 w-10 h-10 bg-[#105DA4] text-white flex items-center justify-center rounded-full shadow-lg hover:bg-[#0a4b7b] border-2 border-white`}>
                <i className='ri-arrow-left-s-line text-[30px]' />
              </button>
              <button
                onClick={handleNext}
                className={`${
                  images.length === 1 && 'hidden'
                } absolute top-1/2 right-4 transform -translate-y-1/2 w-10 h-10 bg-[#105DA4] text-white flex items-center justify-center rounded-full shadow-lg hover:bg-[#0a4b7b] border-2 border-white`}>
                <i className='ri-arrow-right-s-line text-[30px]' />
              </button>
            </div>

            {/* Progress Bar */}
            <div className='mt-3 mb-4'>
              <div className='relative flex items-center'>
                <div className='relative flex-1 h-[5px] rounded-full overflow-hidden bg-Gray-100'>
                  <div
                    className='h-full bg-Gray-400'
                    style={{ width: `${progressBarPercentage}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {isReport &&
          images.length > 0 && ( //
            <div className='pointer-events-auto'>
              <button
                className='flex flex-row items-center gap-2 cursor-pointer'
                onClick={() => {
                  setIsWebcamSnapsView && setIsWebcamSnapsView(true)
                }}>
                <i className={`ri-link-m text-[#075BA9] text-[20px]`} />
                <h3 className='text-[#075BA9] text-small'>View snapshots</h3>
              </button>
            </div>
          )}
        <div>
          <CreditListItem
            title='Device Used'
            data={reportViewData.deviceUsed}
            iconClass='ri-macbook-line'
          />
          <CreditListItem
            title='Location'
            data={reportViewData.location}
            iconClass='ri-map-pin-2-line'
          />
          <CreditListItem
            title='Extra Time Taken'
            data={reportViewData.extraTimeTaken}
            iconClass='ri-timer-2-line'
          />
          <div className='border-[0.5px] border-[#E5E7EB] my-3' />
          <CreditListItem
            title='Filled out only once from IP Address?'
            data={reportViewData.fillOnlyOnceFromIP}
            iconClass='ri-global-line'
          />
          <CreditListItem
            title='Full screen mode always active'
            data={reportViewData.fullScreenAlwaysActive}
            iconClass='ri-fullscreen-line'
          />
        </div>
      </Card>
    </>
  )
}

export default AntiCheatinMonitor
