import { useMemo } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { ModalProps } from 'types'

import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'

import { ReportLogoHeader } from '../../sections'
import {
  AssessmentReportCandidateDetails,
  AssessmentReportCandidateDetailsDto,
} from '../../sections/ReportCandidateDetails/AssessmentReportCandidateDetails'

type AssessmentSnapsPreviewProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  previewData: assessmentReportPreviewData | undefined
  images: any
}

export const AssessmentSnapsPreview = ({
  candidateDetails,
  previewData,
  setIsModalActive,
  isModalActive,
  images,
}: AssessmentSnapsPreviewProps) => {
  const candidateReportDetails = useMemo(() => {
    const details: AssessmentReportCandidateDetailsDto = {
      name: candidateDetails.name,
      candidateNo: candidateDetails.candidateNo,
      email: candidateDetails.email,
      phoneNumber: candidateDetails.phone,
      avatarUrl: candidateDetails.avatarUrl,
      jobTitle: candidateDetails.jobTitle,
      consultantName: candidateDetails.consultantName,
    }
    return details
  }, [candidateDetails])

  const handleClose = () => {
    setIsModalActive(false)
  }
  return (
    <>
      <Modal
        headerTitle={`Assessment Report Snapshots Preview`}
        isFooterShow={true}
        isActive={isModalActive}
        className='!z-[999]'
        onHeaderCloseButtonClick={() => handleClose()}
        onOverlayClick={() => handleClose()}
        modalProps={{
          style: { width: '800px', maxHeight: '90vh' },
        }}>
        <div className='flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
          <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
            <div>
              <div className='h-auto'>
                <ReportLogoHeader
                  clientlogo={previewData?.clientLogoUrl}
                  hasClientLogo={previewData?.hasClientLogo}
                  hasCompanyLogo={previewData?.hasCompanyLogo}
                />

                <div className='m-3'>
                  <AssessmentReportCandidateDetails
                    details={candidateReportDetails}
                  />
                </div>
              </div>
            </div>

            <div className='p-6'>
              <div className='font-SemiBold text-heading-5 leading-4 my-4'>
                Snapshots
              </div>
              <div className='grid grid-cols-2 gap-4'>
                {images.map((image, idx) => (
                  <div key={idx} className='relative w-[360px] h-[175px]'>
                    <img
                      src={image.imageUrl}
                      alt={`Snapshot ${idx + 1}`}
                      className='w-full h-full object-cover rounded'
                    />
                    <div className='absolute top-3 right-3 bg-Gray-100 text-Gray-800 font-Medium text-[10px] leading-4 px-2 py-1 rounded-full'>
                      {image.time}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Column>
        </div>

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button
              onClick={() => handleClose()}
              textTheme='blue'
              theme='white'>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
