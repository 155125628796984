import { useEffect, useMemo, useState } from 'react'
import { Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentReportViewById } from 'api/assessment/getAssessmentReportViewById'
import {
  AssessmentResponseListDto,
  useGetAssessmentResponsesList,
} from 'api/assessment/getAssessmentResponsesList'
import CN from 'classnames'

import { UITable } from 'components/common/UITable'
import { AssessmentDownloadModal } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentDownloadModal'

import { AssessmentResponsesListColumn } from './columns'

type AssessmentResultProps = {
  candidateId: string
  candidateDetails: any
}

export const DownloadableAssessmentsReportTable = ({
  candidateId,
  candidateDetails,
}: AssessmentResultProps) => {
  const notify = (props: any) => Toast(props)
  const [markingValue, setMarkingValue] = useState<any>({
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  })
  const [reportViewData, setReportViewData] = useState<any>(null)

  // Table States & Variables
  const [tableData, setTableData] = useState<AssessmentResponseListDto>({
    skip: 0,
    take: 0,
    assessmentResponses: [],
    totalCount: 0,
    message: '-',
  })
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData?.skip || 0,
    take: tableData?.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  const [pageNumber, setPageNumber] = useState(1)

  const [selectedResponse, setSelectedResponse] = useState<any>()

  const [isDownlaodAssessmentReport, setIsDownlaodAssessmentReport] =
    useState(false)

  const { refetch: getAssessmentReportView } = useGetAssessmentReportViewById(
    {
      candidateId: candidateId,
      assessmentId: selectedResponse?.assessmentId, // Ensure assessmentId is present
      responseId: selectedResponse?.id,
    },
    async (data: any) => {
      setReportViewData(data?.assessmentReport)
      setMarkingValue({
        correct: data?.assessmentReport?.assessmentAutoMarking?.correct,
        partiallyCorrect:
          data?.assessmentReport?.assessmentAutoMarking?.partiallyCorrect,
        incorrect: data?.assessmentReport?.assessmentAutoMarking?.incorrect,
        notAnswered: data?.assessmentReport?.assessmentAutoMarking?.notAnswered,
      })
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  // Fetch assessment list
  const {
    refetch: refreshAssessmentList,
    isLoading: getAssessmentListIsLoading,
    isFetching: getAssessmentListIsFetching,
  } = useGetAssessmentResponsesList(
    {
      candidateId: candidateId,
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      status: 'RESPONSE_RECEVIED',
      emailStatus: '',
      managingConsultant: '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
      ManagingConsultantId: '',
    },
    (data: any) => {
      setTableData(data || {})
      setIsDownlaodAssessmentReport(false)
    },
    ({ response: { data: errData } }: any) => {
      notify({
        alertHeader: errData.messageTitle ? errData.messageTitle : '',
        alertBody: errData.message ? errData.message : 'Error!',
        status: 'Error',
      })
    },
  )

  useEffect(() => {
    refreshAssessmentList()
  }, [paginationVariables])

  useEffect(() => {
    // Only trigger if selectedResponse is not null and has assessmentId
    if (
      selectedResponse &&
      selectedResponse.assessmentId &&
      isDownlaodAssessmentReport
    ) {
      getAssessmentReportView()
    }
  }, [selectedResponse, isDownlaodAssessmentReport])

  const tableColumns = useMemo(() => {
    if (tableData?.assessmentResponses?.length > 0) {
      const columns: any = AssessmentResponsesListColumn(
        setSelectedResponse,
        setIsDownlaodAssessmentReport,
      )
      return columns
    }
    return []
  }, [tableData])

  const handleCloseModals = () => {
    setIsDownlaodAssessmentReport(false)
  }

  return (
    <div>
      {tableData.assessmentResponses.length > 0 && tableData.message && (
        <div
          className={CN(
            'rounded-[8px] p-4 h-[50px] border-[1px] focus:outline-none flex items-center w-full bg-Blue-50 border-Blue-200 focus:outline-1 mb-5',
          )}>
          <span>
            <i className='ri-information-fill text-[#027EAB] text-base' />
          </span>
          <span
            className={CN(
              'flex w-max flex-row items-center px-2.5 text-small leading-3 font-Medium text-[#027EAB]',
            )}>
            {tableData.message}
          </span>
        </div>
      )}

      <div className='w-full h-[535px] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.assessmentResponses || []}
            isLoading={
              getAssessmentListIsLoading || getAssessmentListIsFetching
            }
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
      {isDownlaodAssessmentReport && reportViewData && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={isDownlaodAssessmentReport}
          setIsModalActive={setIsDownlaodAssessmentReport}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
        />
      )}
    </div>
  )
}
