import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Toast } from '@labourhub/labour-hub-ds'
import {
  ReportCandidateSentimentDto,
  ReportRefereeStatsCountDto,
} from 'api/executiveSummary'
import sampleCandidate from 'assets/images/sample-candidate.png'
import { getReportSettings } from 'features/settings'
import { useGetAgencyDetails } from 'framework/api/methods'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { envConfig } from 'config/framework.config'

import { ReportDetailColumns } from '../ReportDetailColumns'

import { ReportSentimentStats } from './ReportSentimentStats'
import { TotalRefereesSection } from './TotalRefereesSection'

type ReferenceReportCandidateDetailsProps = {
  isSampleView?: boolean
  details: ReferenceReportCandidateDetailsDto
  stats: ReportRefereeStatsCountDto
  sentiment: ReportCandidateSentimentDto
}

export type ReferenceReportCandidateDetailsDto = {
  name: string
  candidateNo: string
  email: string
  phoneNumber: string
  avatarUrl?: string
  jobTitle?: string
  consultantName: string
}

export const ReferenceReportCandidateDetails = ({
  details,
  stats,
  sentiment,
  isSampleView = false,
}: ReferenceReportCandidateDetailsProps) => {
  const notify = (props: any) => Toast(props)

  const { textColour } = useSelector(getReportSettings)

  const [agencyProfileDetails, setAgencyProfileDetails] = useState<any>({})

  /** Get agency profile details on success */
  const getAgencyDetailsOnSuccess = ({ data: successData }: any) => {
    setAgencyProfileDetails(successData?.agencyProfile)
  }

  /** Get agency profile details on error */
  const getAgencyDetailsOnError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API call for getting agency profile details */
  const { refetch: getAgencyProfileDetailsRefetch } = useGetAgencyDetails(
    {},
    getAgencyDetailsOnSuccess,
    getAgencyDetailsOnError,
  )

  /** Hide candidate details for specific agency */
  const reportCandidateDetailHideId =
    envConfig?.reportCandidateDetailHideTenantId

  useEffect(() => {
    getAgencyProfileDetailsRefetch()
  }, [])

  return (
    <Column className='px-6 py-4 mt-4'>
      <Row className='w-full h-auto space-x-4'>
        {isSampleView && (
          <img
            src={sampleCandidate}
            alt=''
            className='object-fill rounded-full w-[62px] h-[62px] overflow-hidden '
          />
        )}

        {details.avatarUrl && (
          <img
            src={details.avatarUrl}
            alt=''
            className='object-fill rounded-full w-[62px] h-[62px] overflow-hidden '
          />
        )}
        <div className='max-w-[650px]'>
          <p className='text-small text-Gray-500 font-Bold'>REFERENCE REPORT</p>
          <p
            className='leading-6 uppercase text-heading-3 font-Bold'
            style={{ color: textColour }}>
            {details.name}
          </p>
          {details.jobTitle && (
            <p className='text-base font-Medium text-Gray-600'>
              Applying for role of {details.jobTitle}
            </p>
          )}
        </div>
      </Row>

      <div className='py-4 mt-5'>
        <TotalRefereesSection stats={stats} />
      </div>
      {agencyProfileDetails?.id?.toLowerCase() !==
        reportCandidateDetailHideId?.toLowerCase() && (
        <div className='py-4 mt-5'>
          <ReportDetailColumns
            title='Candidate Details'
            leftDetails={[
              { label: 'ID No', value: details.candidateNo, type: 'text' },
              { label: 'Phone', value: details.phoneNumber, type: 'link' },
            ]}
            rightDetails={[
              { label: 'Email', value: details.email, type: 'link' },
              {
                label: 'Managing Consultant',
                value: details.consultantName,
                type: 'text',
              },
            ]}
          />
        </div>
      )}

      {/* Sentiment Temporary Hide */}
      {/* {((sentiment && sentiment.positiveSentiment > 0) ||
        sentiment.negativeSentiment > 0 ||
        sentiment.neutralSentiment > 0) && (
        <div className='pb-5 mt-5 border-b border-Gray-200'>
          <ReportSentimentStats sentiment={sentiment} />
        </div>
      )} */}
    </Column>
  )
}
