import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

type GetAssessmentReportViewByIdProps = {
  candidateId: string
  assessmentId: string
  responseId: string
}
const getAssessmentReportViewById = async ({ queryKey }: any) => {
  const { candidateId, assessmentId, responseId } =
    queryKey[1] as GetAssessmentReportViewByIdProps
  const res = await get(
    `/candidate/${candidateId}/assessment/${assessmentId}/response/${responseId}/view-assessment
`,
  )
  return res.data
}
export const useGetAssessmentReportViewById = (
  reqBody: GetAssessmentReportViewByIdProps,
  onSuccess: (data: any) => void,
  onError: (error: any) => void,
) => {
  return useQuery(['AssessmentReport', reqBody], getAssessmentReportViewById, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
