import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSortable } from '@dnd-kit/sortable'
import {
  Checkbox,
  Radio,
  SimpleSelect,
  TextArea,
  TextField,
} from '@labourhub/labour-hub-ds'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { motion } from 'framer-motion'

import { Rate } from 'components/atoms'
import IFrameVideo from 'components/atoms/IFrameVideo/IFrameVideo'
import ImageViewer from 'components/atoms/ImageViewer/ImageViewer'
import { cn } from 'utils'
import { setEmbededVideoLink } from 'utils/setEmbededVideoLink'

export type DragOption = {
  isDragging: boolean
  activeIndex?: number
  overIndex?: number
}

type AssessmentPreviewQuestionCardProps = {
  index: number
  filteredIndex: number
  question: AssessmentQuestion
  isReadOnly?: boolean
}

export const AssessmentPreviewQuestionCard = ({
  index,
  filteredIndex,
  question,
  isReadOnly = true,
}: AssessmentPreviewQuestionCardProps) => {
  const { setNodeRef, transform, isDragging, overIndex } = useSortable({
    id: question.id,
  })
  const [checkedItems, setCheckedItems] = useState<any[]>([])

  useEffect(() => {
    if (
      question.type === 'checkbox' &&
      question.correctAnswer !== '' &&
      question.correctAnswer !== null &&
      question.correctAnswer !== undefined
    ) {
      try {
        const storedCheckboxes = JSON.parse(question.correctAnswer) || []
        setCheckedItems(storedCheckboxes)
      } catch (error) {
        console.error('Error parsing JSON:', error)
        setCheckedItems(checkedItems)
      }
    }
  }, [question.correctAnswer, question.type])

  const formattedIndex =
    filteredIndex === -1
      ? null
      : filteredIndex >= 0 && filteredIndex <= 8
      ? `0${filteredIndex + 1}`
      : `${filteredIndex + 1}`
  return (
    <div className='bg-white p-5 rounded-md'>
      <motion.div
        className={cn(
          'relative group w-full h-fit flex flex-col rounded-lg border border-Gray-300 bg-white hover:border-Cobalt-200 select-none',
          {
            'border-dashed': !isDragging,
          },
        )}
        ref={setNodeRef}
        layoutId={String(question.id)}
        style={{
          transform: transform?.[index]
            ? `translateY(${transform[index]}px)`
            : 'none',
          opacity: isDragging && index === overIndex ? 0.5 : 1,
        }}>
        <div
          className={cn(
            'flex flex-col items-start justify-start p-4 space-y-4',
            {
              'pointer-events-none': isReadOnly,
            },
          )}>
          {question.type != 'heading' && question.type != 'paragraph' && (
            <div className=' px-2 py-1 max-w-[120px] border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
              Question {formattedIndex}
            </div>
          )}

          <p
            className={cn('w-full text-Gray-800 whitespace-pre-wrap', {
              'text-[1.2rem] font-[700]': question.type === 'heading',
              'font-Medium': question.type != 'heading',
              'whitespace-pre-line': question.type == 'paragraph',
              "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
                question.isRequired,
            })}>
            {question.title}
          </p>

          <div className='w-full flex space-x-3 justify-evenly items-center'>
            {question.imageUrl && <ImageViewer imageUrl={question.imageUrl} />}
            {question.videoLink && <IFrameVideo link={question.videoLink} />}

            <div
              className={`${
                question.imageUrl || question.videoLink ? 'w-1/2' : 'w-full'
              }`}>
              {question.type === 'text' && (
                <TextField
                  className='w-full'
                  isDisabled={true}
                  value=''
                  placeholder=''
                />
              )}

              {question.type === 'text_area' && (
                <TextArea
                  className='w-full'
                  value=''
                  isDisabled={true}
                  placeholder=''
                  rows={4}
                />
              )}

              {question.type === 'textarea' && (
                <TextArea
                  className='w-full'
                  value=''
                  isDisabled={true}
                  placeholder=''
                  rows={4}
                />
              )}

              {question.type === 'rating' && (
                <Rate rating='0' count={Number(question.meta.length || '5')} />
              )}

              {question.type === 'checkbox' && (
                <div className='flex flex-col gap-y-2'>
                  {[...question.meta].map((item, idx) => (
                    <Checkbox
                      key={idx}
                      id={`custom-checkbox-00${idx}`}
                      labelText={item}
                      labelTextClassName='font-Regular'
                      checkboxClassName='ml-0'
                    />
                  ))}
                </div>
              )}

              {question.type === 'select' && (
                <SimpleSelect
                  className='w-full'
                  value={null}
                  options={[]}
                  placeholder='Select Option'
                />
              )}

              {question.type === 'radio' && (
                <div className='flex gap-6'>
                  <Radio
                    id={`yesss_${question.id}`}
                    className='cursor-pointer select-none'
                    name={`status_${question.id}`}
                    helperText=''
                    labelText='Yes'
                  />

                  <Radio
                    className='cursor-pointer select-none'
                    id={`nooo_${question.id}`}
                    name={`status_${question.id}`}
                    helperText=''
                    labelText='No'
                  />
                </div>
              )}

              {(question.type === 'radio_neg_detail' ||
                question.type === 'radio_pos_detail') && (
                <div className='flex flex-col w-full'>
                  <div className='flex gap-6'>
                    <Radio
                      id={`0${question.id}-detailed-yes`}
                      className='cursor-pointer select-none'
                      name={`0${question.id}-detailed`}
                      helperText=''
                      labelText='Yes'
                    />

                    <Radio
                      className='cursor-pointer select-none'
                      id={`0${question.id}-detailed-yes`}
                      name={`0${question.id}-detailed`}
                      helperText=''
                      labelText='No'
                    />
                  </div>

                  <TextArea
                    className='w-full mt-3'
                    isDisabled={true}
                    label='Explain'
                    value=''
                    placeholder=''
                    rows={4}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
      <div
        className={cn(
          'mt-3 border-dashed pointer-events-none w-full h-fit flex flex-col items-start justify-start space-y-4 rounded-lg border border-[#DD7F0B] hover:border-[#E66962] bg-Gray-50 select-none p-4',
          {
            hidden: question.type == 'heading' || question.type == 'paragraph',
          },
        )}>
        <div className='flex justify-between w-full'>
          <div className=' px-2 py-1 max-w-[180px] border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
            Correct Answer
          </div>
          <div className='font-Medium w-5 h-5 text-heading-4 pointer-events-auto'>
            <i
              className='ri-information-line'
              data-tip='The correct answer is used only to grade the applicant and will not be shown to the candidate at any point. Please provide detailed descriptions where possible to achieve the best results and for future reference.'
              data-for='answer-information'
            />
            <ReactTooltip
              place='bottom'
              id='answer-information'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#1F2937'
              className='min-h-[100px] w-[310px] text-extra-small'
            />
          </div>
        </div>

        <p
          className={cn('w-full text-Gray-800 whitespace-pre-wrap', {
            'text-[1.2rem] font-[700]': question.type === 'heading',
            'font-Medium': question.type != 'heading',
            'whitespace-pre-line': question.type == 'paragraph',
            "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
              question.isRequired,
          })}>
          {question.title}
        </p>

        <div className='w-full flex space-x-3 justify-evenly items-center'>
          {question.imageUrl && (
            <div className='w-1/2'>
              <img
                src={question.imageUrl}
                alt='drag-and-drop-area'
                className={cn('rounded max-h-[140px] w-full')}
              />
            </div>
          )}
          {question.videoLink && (
            <div className='w-1/2 pointer-events-auto'>
              <iframe
                height={235}
                className='w-full'
                src={setEmbededVideoLink(question.videoLink)}
                title='Video Preview'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          )}
          <div
            className={`${
              question.imageUrl || question.videoLink ? 'w-1/2' : 'w-full'
            }`}>
            {question.type === 'text' && (
              <TextField
                className='w-full'
                isDisabled={true}
                value={question.correctAnswer}
                placeholder=''
              />
            )}

            {question.type === 'text_area' && (
              <TextArea
                className='w-full'
                value={question.correctAnswer}
                isDisabled={true}
                placeholder=''
                rows={4}
              />
            )}

            {question.type === 'textarea' && (
              <TextArea
                className='w-full'
                value={question.correctAnswer}
                isDisabled={true}
                placeholder=''
                rows={4}
              />
            )}

            {question.type === 'rating' && (
              <Rate
                rating={question.correctAnswer}
                count={Number(question.meta.length || '5')}
              />
            )}

            {question.type === 'checkbox' && (
              <div className='flex flex-col gap-y-2'>
                {[...question.meta].map((item, idx) => {
                  return (
                    <Checkbox
                      key={idx}
                      id={`custom-checkbox-0${idx}`}
                      labelText={item}
                      checked={checkedItems.includes(item)}
                      labelTextClassName='font-Regular'
                      checkboxClassName='ml-0'
                    />
                  )
                })}
              </div>
            )}

            {question.type === 'select' && (
              <SimpleSelect
                className='w-full'
                value={{
                  value: question.correctAnswer,
                  label: question.correctAnswer,
                }}
                options={question.meta.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder='Select Option'
              />
            )}

            {question.type === 'radio' && (
              <div className='flex gap-6'>
                <Radio
                  id={`StatusYes_${question.id}`}
                  checked={question.correctAnswer === 'yes'}
                  className='cursor-pointer'
                  name={`YesNoStatus_${question.id}`}
                  helperText=''
                  labelText=' Yes'
                />

                <Radio
                  className='cursor-pointer'
                  id={`StatusYes_${question.id}`}
                  checked={question.correctAnswer === 'no'}
                  name={`YesNoStatus_${question.id}`}
                  helperText=''
                  labelText=' No'
                />
              </div>
            )}

            {(question.type === 'radio_neg_detail' ||
              question.type === 'radio_pos_detail') && (
              <div className='flex flex-col w-full'>
                <div className='flex gap-6'>
                  <Radio
                    checked={question.type === 'radio_pos_detail'}
                    id={`${question.id}-detailed-yes`}
                    className='cursor-pointer select-none'
                    name={`00${question.id}-detailed`}
                    helperText=''
                    labelText='Yes'
                  />

                  <Radio
                    checked={question.type === 'radio_neg_detail'}
                    className='cursor-pointer select-none'
                    id={`${question.id}-detailed-yes`}
                    name={`00${question.id}-detailed`}
                    helperText=''
                    labelText='No'
                  />
                </div>

                <TextArea
                  className='w-full mt-3'
                  isDisabled={true}
                  label='Explain'
                  value={question.correctAnswer}
                  placeholder=''
                  rows={4}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
