import React, { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import { UITable } from 'components/common/UITable'
import { QuestionType } from 'utils/getQuestionType'

import { AssesmentAnswerRatingModal } from '../DownloadReport/AssesmentAnswerRatingModal'

import { RatingTableColumn } from './columns'

type RatingTableProps = {
  reportViewData: any
  setJsonSchema: React.Dispatch<any>
  jsonSchema: any
  fetchRealtimeRatingData: (updatedJsonSchema: any) => void
  isRealtimeAssessmentRatingLoading: boolean
}

const RatingTable = ({
  reportViewData,
  setJsonSchema,
  jsonSchema,
  fetchRealtimeRatingData,
  isRealtimeAssessmentRatingLoading,
}: RatingTableProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any>()
  const [isShowRatingModal, setIsShowRatingModal] = useState(false)
  const [questionData, setQuestionData] = useState(
    reportViewData?.reportQuestion.filter(
      (item) =>
        item.questionType !== QuestionType.Heading &&
        item.questionType !== QuestionType.Paragraph,
    ) || [],
  )
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: 0,
    take: 100,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  const tableColumns = useMemo(() => {
    if (questionData && questionData.length > 0) {
      const columns: any = RatingTableColumn(
        setIsShowRatingModal,
        setSelectedTemplate,
      )

      return columns
    }
    return []
  }, [questionData, jsonSchema])

  const updateProperties = (originalObject, questions) => {
    // Create a deep copy of the original object to avoid mutating it directly
    const updatedObject = { ...originalObject }

    // Ensure `properties` exists in the `updatedObject`
    const properties = updatedObject.properties || {}

    Object.keys(properties).forEach((key) => {
      const question = properties[key]
      const questionItem = questions.find((item) => item.questionID === key)

      if (questionItem) {
        const updatedProperties = {
          ...question.properties,
          comment:
            questionItem.comment !== null
              ? { type: 'string', description: questionItem.comment }
              : question.properties?.comment,
          rate:
            questionItem.answerRating !== null
              ? { type: 'number', description: questionItem.answerRating }
              : question.properties?.rate,
          isAIGenerated: {
            type: 'boolean',
            description: questionItem.isAiGenerated.toString(),
          },
        }

        // Add the updated properties to the question
        question.properties = updatedProperties
      } else {
        // If questionItem does not exist, ensure question.properties is initialized
        if (!question.properties) {
          question.properties = {}
        }

        // Add properties from questionItem if available
        if (questionItem) {
          question.properties.comment =
            questionItem.comment !== null
              ? { type: 'string', description: questionItem.comment }
              : undefined
          question.properties.rate =
            questionItem.answerRating !== null
              ? { type: 'number', description: questionItem.answerRating }
              : undefined
          question.properties.isAIGenerated = {
            type: 'boolean',
            description: questionItem.isAiGenerated.toString(),
          }
        }
      }
    })

    return JSON.stringify(updatedObject)
  }

  useEffect(() => {
    setJsonSchema(updateProperties(JSON.parse(jsonSchema), questionData))
  }, [questionData])

  return (
    <div>
      <div className='w-full h-auto relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-52px)] bg-white rounded-t-lg border border-Gray-200',
          )}>
          <UITable
            data={questionData || []}
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={false}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            isPaginated={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
          />
        </div>
      </div>
      {isShowRatingModal && (
        <AssesmentAnswerRatingModal
          isActive={isShowRatingModal}
          modalClose={() => setIsShowRatingModal(false)}
          selectedTemplate={selectedTemplate}
          setJsonSchema={setJsonSchema}
          jsonSchema={jsonSchema}
          setSelectedTemplate={setSelectedTemplate}
          setQuestionData={setQuestionData}
          questionData={questionData}
          fetchRealtimeRatingData={fetchRealtimeRatingData}
          isRealtimeAssessmentRatingLoading={isRealtimeAssessmentRatingLoading}
        />
      )}
    </div>
  )
}

export default RatingTable
