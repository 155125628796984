import { useEffect, useRef } from 'react'
import { Modal } from '@labourhub/labour-hub-ds'
import { useGetDynamicLibraryFormAssessment } from 'api/assessment'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { FormBuilderCustom } from 'components/molecules/FormBuilder/FormBuilderCustom'
import { updateNode } from 'utils/updateNode'

type AssessmentLibraryPreviewModalProps = ModalProps & {
  templateId: string
  templateName: string
}

export const AssessmentLibraryPreviewModal = ({
  isModalActive,
  setIsModalActive,
  templateId,
  templateName,
}: AssessmentLibraryPreviewModalProps) => {
  /** get dynamic form */
  const {
    data: dynamicForm,
    refetch: getDynamicForm,
    isLoading: getDynamicFormIsLoading,
  } = useGetDynamicLibraryFormAssessment(templateId)
  const componentRef = useRef<HTMLDivElement>(null)

  /** call get dynamic form when template id updated */
  useEffect(() => {
    templateId && isModalActive && getDynamicForm()
  }, [isModalActive, templateId])

  const onModalClose = () => setIsModalActive(false)

  useEffect(() => {
    updateNode(componentRef)
  }, [dynamicForm])

  return (
    <Modal
      headerTitle={`Preview - ${templateName}`}
      customWidth='1000px'
      isActive={isModalActive}
      primaryButtonTheme={'cobalt'}
      primaryButtonTitle=''
      footerButtonAlignment='right'
      onClickSecondaryBtn={onModalClose}
      onHeaderCloseButtonClick={onModalClose}
      onOverlayClick={onModalClose}
      secondaryButtonTitle='Close'>
      {getDynamicFormIsLoading && <PageLoader size='xxs' />}

      <div
        ref={componentRef}
        className='flex flex-col w-full justify-start px-8 py-5 min-h-[30vh] max-h-[80vh] overflow-y-auto'>
        {/* form builder */}
        {dynamicForm && (
          <FormBuilderCustom
            schema={JSON.parse(dynamicForm.jsonSchema)}
            uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
            readonly={true}
          />
        )}
      </div>
    </Modal>
  )
}
