import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type CreateCandidateNoteProps = {
  candidateId: string
  formDetails: any
}

export const useCreateCandidateNote = () => {
  const mutate = useMutation(
    ({ formDetails, candidateId }: CreateCandidateNoteProps) => {
      return postAPI(`/candidate/note`, {
        id: candidateId,
        type: formDetails?.type,
        description: formDetails?.note,
      })
    },
  )

  return mutate
}
