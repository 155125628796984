import { useEffect, useState } from 'react'

import IFrameVideo from 'components/atoms/IFrameVideo/IFrameVideo'
import ImageViewer from 'components/atoms/ImageViewer/ImageViewer'

const CustomTemplate = (props: any) => {
  const { schema, children, idSchema, uiSchema } = props
  const imageUrl =
    schema?.properties?.imageurl?.description || schema?.properties?.imageurl
  const videoUrl =
    schema?.properties?.videourl?.description || schema?.properties?.videourl

  const [getRootId, setGetRootId] = useState('')

  const getSecondKeyValuePair = (object) => {
    const keys = Object.keys(object)
    if (keys.length < 2) {
      return null // or handle as needed if there are less than 2 keys
    }
    const secondKey = keys[1]
    return {
      key: secondKey,
      value: object[secondKey],
    }
  }
  const widget = uiSchema?.['ui:widget'] ?? ''
  const isRating = widget == 'rating' ? true : false
  useEffect(() => {
    const secondKeyValuePair = getSecondKeyValuePair(idSchema)

    if (secondKeyValuePair) {
      const secondValue = secondKeyValuePair.value
      const val = Object.values(secondValue).toString()
      setGetRootId(val)
    }
  }, [])

  return (
    <>
      {!isRating ? (
        <div
          className='w-full flex gap-3 items-center mt-4'
          id='appendinContainer'>
          {imageUrl && <ImageViewer imageUrl={imageUrl} />}
          {videoUrl && <IFrameVideo link={videoUrl} />}
          <div
            className={`${
              imageUrl || videoUrl ? 'w-1/2' : 'w-full'
            } append-class-container`}
            id={getRootId}>
            {children}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
export default CustomTemplate
