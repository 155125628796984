/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactTooltip from 'react-tooltip'
import { Badge } from '@labourhub/labour-hub-ds'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'
import moment from 'moment'

import { MoreMenuButton } from 'components/atoms'
import { timeToAESTWithSeconds } from 'utils'

export const AssessmentResponsesListColumn = (
  setSelectedResponse: any,
  setIsReminderModalActive: any,
  setViewAssessmentModal: any,
  setViewAssessmentModalReport: any,
  setIsDownlaodAssessmentReport: any,
  setIsCancelRequestModalActive: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const COLUMNS = [
    {
      Header: 'ASSESSMENT NAME',
      accessor: 'AssessmentName',
      forSorting: 'AssessmentName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[175px] lg:w[200px] w-[200px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className=''>
            <p
              className=' cursor-pointer text-Cobalt-400 flex items-center gap-0.5'
              data-tip={original?.assessmentName}
              data-for={original?.assessmentId}
              onClick={() => {
                setSelectedResponse(original)

                if (
                  original?.status === 'RESPONSE_RECEVIED' ||
                  original?.status === 'RESPONSE_EVALUATED' ||
                  original?.status === 'RESPONSE_IN_REVIEW'
                ) {
                  setViewAssessmentModalReport(true)
                } else {
                  setViewAssessmentModal(true)
                }
              }}>
              <ReactTooltip
                place='right'
                id={original?.assessmentId}
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                delayShow={800}
              />
              <span className='truncate max-w-[120px]'>
                {original?.assessmentName}{' '}
              </span>
              <span>
                <img
                  className='w-[20px]'
                  src={difficultyImages[original.difficultyLevel]}
                  alt={original.difficultyLevel}
                />
              </span>
            </p>
          </div>
        )
      },
    },
    {
      Header: 'SENT BY',
      accessor: 'SentBy',
      forSorting: 'SentBy',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            className='truncate'
            data-tip={original?.sentBy}
            data-for={original?.assessmentId}>
            <ReactTooltip
              place='right'
              id={original?.assessmentId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {original?.sentBy}
          </div>
        )
      },
    },
    {
      Header: 'EMAIL',
      accessor: 'Response',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[120px] lg:w[140px] w-[140px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const badgeTheme =
          original?.status === 'AWAITING_RESPONSE' ||
          original?.status === 'REQUEST_CANCELLED'
            ? 'gray'
            : 'green'
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {original?.status === 'AWAITING_RESPONSE' ||
                original?.status === 'REQUEST_CANCELLED'
                  ? 'Sent'
                  : 'Opened'}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'SENT ON',
      accessor: 'SentOn',
      forSorting: 'SentOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[120px] lg:w[140px] w-[140px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const createdDateStringWithSeconds = timeToAESTWithSeconds(
          original?.sentOn,
        )
        const createdDateString = moment(original?.sentOn).format('DD/MM/yyyy')
        return (
          <div
            data-tip={createdDateStringWithSeconds}
            data-for={original?.assessmentId}>
            <ReactTooltip
              place='right'
              id={original?.assessmentId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {createdDateString}
          </div>
        )
      },
    },
    {
      Header: 'COMPLETED ON',
      accessor: 'CompletedOn',
      forSorting: 'CompletedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[150px] lg:w[170px] w-[170px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const updatedDateStringWithSecond =
          original?.completedOn !== null
            ? timeToAESTWithSeconds(original?.completedOn)
            : '-'
        const updatedDateString =
          original?.completedOn !== null
            ? moment(original?.completedOn).format('DD/MM/yyyy')
            : '-'
        return (
          <div
            data-tip={updatedDateStringWithSecond}
            data-for={original?.assessmentId}>
            <ReactTooltip
              place='right'
              id={original?.assessmentId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {updatedDateString}
          </div>
        )
      },
    },
    {
      Header: 'RESPONSE',
      accessor: 'Status',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const badgeTheme =
          original?.status === 'AWAITING_RESPONSE'
            ? 'yellow'
            : original?.status === 'REQUEST_DECLINED'
            ? 'red'
            : original?.status === 'RESPONSE_RECEVIED'
            ? 'green'
            : original?.status === 'RESPONSE_IN_REVIEW'
            ? 'yellow'
            : original?.status === 'RESPONSE_EVALUATED' ||
              original?.status === 'RESPONSE_IN_REVIEW'
            ? 'blue'
            : original?.status === 'REQUEST_CANCELLED'
            ? 'red'
            : original?.status === 'REQUEST_EXPIRED'
            ? 'purple'
            : 'gray'
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {original?.status === 'RESPONSE_RECEVIED'
                  ? 'Received'
                  : original?.status === 'AWAITING_RESPONSE'
                  ? 'Awaiting'
                  : original?.status === 'RESPONSE_IN_REVIEW'
                  ? 'In Review'
                  : original?.status === 'RESPONSE_EVALUATED'
                  ? 'Evaluated'
                  : original?.status === 'REQUEST_DECLINED'
                  ? 'Declined'
                  : original?.status === 'REQUEST_CANCELLED'
                  ? 'Cancelled'
                  : original?.status === 'REQUEST_EXPIRED'
                  ? 'Expired'
                  : 'Not Sent'}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[50px] lg:w[60px] w-[60px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='relative'>
            {original.status === 'REQUEST_DECLINED' ||
            original.status === 'REQUEST_CANCELLED' ||
            original.status === 'REQUEST_EXPIRED' ? null : (
              <MoreMenuButton
                className='ml-2'
                actionButtons={
                  original.status === 'AWAITING_RESPONSE'
                    ? [
                        {
                          iconClass: 'ri-send-plane-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsReminderModalActive(true)
                          },
                          title: 'Send Reminder',
                          status: 'normal',
                        },
                        {
                          disabled: true,
                          iconClass: 'ri-eye-line',
                          onClick: () => {
                            // setSelectedResponse(original)
                            // setViewAssessmentModal(true)
                          },
                          title: 'View Assessment',
                          status: 'normal',
                        },
                        {
                          iconClass: 'ri-mail-close-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsCancelRequestModalActive(true)
                          },
                          title: 'Cancel Request',
                          status: 'danger',
                        },
                      ]
                    : original.status === 'RESPONSE_RECEVIED'
                    ? [
                        {
                          iconClass: 'ri-eye-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setViewAssessmentModalReport(true)
                          },
                          title: 'View Assessment',
                          status: 'normal',
                        },
                        {
                          iconClass: 'ri-download-2-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsDownlaodAssessmentReport(true)
                          },
                          title: 'Download Assessment',
                          status: 'normal',
                        },
                      ]
                    : original.status === 'RESPONSE_IN_REVIEW'
                    ? [
                        {
                          iconClass: 'ri-eye-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setViewAssessmentModalReport(true)
                          },
                          title: 'View Assessment',
                          status: 'normal',
                        },
                        {
                          iconClass: 'ri-download-2-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsDownlaodAssessmentReport(true)
                          },
                          title: 'Download Assessment',
                          status: 'normal',
                        },
                      ]
                    : original.status === 'RESPONSE_EVALUATED'
                    ? [
                        {
                          iconClass: 'ri-eye-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setViewAssessmentModalReport(true)
                          },
                          title: 'View Assessment',
                          status: 'normal',
                        },
                        {
                          iconClass: 'ri-download-2-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsDownlaodAssessmentReport(true)
                          },
                          title: 'Download Assessment',
                          status: 'normal',
                        },
                      ]
                    : []
                }
              />
            )}
          </div>
        )
      },
    },
  ]
  return COLUMNS
}
