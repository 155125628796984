import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Radio,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'

import { Rate } from 'components/atoms'
import { TextArea } from 'components/molecules/TextArea'
import { cn } from 'utils'
import { setEmbededVideoLink } from 'utils/setEmbededVideoLink'

export const AnswerPreview = ({
  selectedTemplate,
}: {
  selectedTemplate: any
}) => {
  const [checkedItems, setCheckedItems] = useState<any[]>([])

  useEffect(() => {
    if (
      (selectedTemplate.questionType === 'checkbox' ||
        selectedTemplate.questionType === 'checkboxes') &&
      selectedTemplate.correctAnswer !== '' &&
      selectedTemplate.correctAnswer !== null &&
      selectedTemplate.correctAnswer !== undefined
    ) {
      try {
        const storedCheckboxes =
          JSON.parse(selectedTemplate.correctAnswer) || []
        setCheckedItems(storedCheckboxes)
      } catch (error) {
        console.error('Error parsing JSON:', error)
        setCheckedItems(checkedItems)
      }
    }
  }, [selectedTemplate.correctAnswer, selectedTemplate.questionType])

  const renderAnswerPreview = (questionType: string) => {
    switch (questionType) {
      case 'textarea':
      case 'text_area':
      case 'text':
        return (
          <TextArea
            className='w-full'
            value={selectedTemplate.correctAnswer}
            isDisabled={true}
            placeholder=''
            rows={4}
          />
        )
      case 'rating':
        return (
          <Rate
            rating={selectedTemplate.correctAnswer}
            count={Number(
              JSON.parse(selectedTemplate.answerDetails).length || '5',
            )}
          />
        )
      case 'checkbox':
      case 'checkboxes':
        return (
          <div className='flex flex-col gap-y-2'>
            {JSON.parse(selectedTemplate.answerDetails).map((item, idx) => (
              <Checkbox
                key={idx}
                id={`custom-checkbox-0${idx}`}
                labelText={item}
                checked={checkedItems.includes(item)}
                labelTextClassName='font-Regular'
                checkboxClassName='ml-0'
              />
            ))}
          </div>
        )
      case 'select':
        return (
          <SimpleSelect
            className='w-full'
            value={{
              value: selectedTemplate.correctAnswer,
              label: selectedTemplate.correctAnswer,
            }}
            options={JSON.parse(selectedTemplate.answerDetails).map(
              (option) => ({
                value: option,
                label: option,
              }),
            )}
            placeholder='Select Option'
          />
        )
      case 'radio':
        return (
          <div className='flex gap-6'>
            <Radio
              id={`StatusYes_${selectedTemplate.id}`}
              checked={selectedTemplate.correctAnswer === 'yes'}
              className='cursor-pointer'
              name={`YesNoStatus_${selectedTemplate.id}`}
              helperText=''
              labelText=' Yes'
            />
            <Radio
              className='cursor-pointer'
              id={`StatusYes_${selectedTemplate.id}`}
              checked={selectedTemplate.correctAnswer === 'no'}
              name={`YesNoStatus_${selectedTemplate.id}`}
              helperText=''
              labelText=' No'
            />
          </div>
        )
      case 'radio_neg_detail':
      case 'radio_pos_detail':
        return (
          <div className='flex flex-col w-full'>
            <div className='flex gap-6'>
              <Radio
                checked={questionType === 'radio_pos_detail'}
                id={`${selectedTemplate.id}-detailed-yes`}
                className='cursor-pointer select-none'
                name={`00${selectedTemplate.id}-detailed`}
                helperText=''
                labelText='Yes'
              />
              <Radio
                checked={questionType === 'radio_neg_detail'}
                className='cursor-pointer select-none'
                id={`${selectedTemplate.id}-detailed-yes`}
                name={`00${selectedTemplate.id}-detailed`}
                helperText=''
                labelText='No'
              />
            </div>
            <TextArea
              className='w-full mt-3'
              isDisabled={true}
              label='Explain'
              value={selectedTemplate.correctAnswer}
              placeholder=''
              rows={4}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='w-[100%]'>
      <div
        className={cn(
          'mt-3 border-dashed w-full h-fit flex flex-col items-start justify-start space-y-4 rounded-lg border border-[#DD7F0B] hover:border-[#E66962] select-none p-4',
          {
            hidden:
              selectedTemplate.questionType === 'heading' ||
              selectedTemplate.questionType === 'paragraph',
          },
        )}>
        <div className='flex justify-between w-full'>
          <div className='px-2 py-1 max-w-[180px] border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
            Correct Answer
          </div>
        </div>
        <p
          className={cn('w-full text-Gray-800 whitespace-pre-wrap', {
            'text-[1.2rem] font-[700]':
              selectedTemplate.questionType === 'heading',
            'font-Medium': selectedTemplate.questionType !== 'heading',
            'whitespace-pre-line':
              selectedTemplate.questionType === 'paragraph',
            "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
              selectedTemplate.isRequired,
          })}>
          {selectedTemplate.question}
        </p>
        <div className='w-full flex space-x-3 justify-evenly items-center'>
          {selectedTemplate.imageUrl && (
            <div className='w-1/2'>
              <img
                src={selectedTemplate.imageUrl}
                alt='drag-and-drop-area'
                className={cn('rounded max-h-[140px] w-full')}
              />
            </div>
          )}
          {selectedTemplate.videoLink && (
            <div className='w-1/2 pointer-events-auto'>
              <iframe
                height={235}
                className='w-full'
                src={setEmbededVideoLink(selectedTemplate.videoLink)}
                title='Video Preview'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          )}
          <div
            className={`${
              selectedTemplate.imageUrl || selectedTemplate.videoLink
                ? 'w-1/2'
                : 'w-full'
            }`}>
            {renderAnswerPreview(selectedTemplate.questionType)}
          </div>
        </div>
      </div>
    </div>
  )
}
