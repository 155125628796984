import { useSelector } from 'react-redux'
import { getReportSettings } from 'features/settings'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'

type AssessmentReportCandidateDetailsProps = {
  isSampleView?: boolean
  details: AssessmentReportCandidateDetailsDto
}

export type AssessmentReportCandidateDetailsDto = {
  name: string
  candidateNo: string
  email: string
  phoneNumber: string
  avatarUrl?: string
  jobTitle?: string
  consultantName: string
}

export const AssessmentReportCandidateDetails = ({
  details,
}: AssessmentReportCandidateDetailsProps) => {
  const { textColour } = useSelector(getReportSettings)
  return (
    <Column className='px-6 py-4 mt-4'>
      <Row className='w-full h-auto space-x-4'>
        {details.avatarUrl && (
          <img
            src={details.avatarUrl}
            alt=''
            className='object-fill rounded-full w-[62px] h-[62px] overflow-hidden '
          />
        )}
        <div className='max-w-[650px]'>
          <p className='text-small text-Gray-500 font-Bold'>
            ASSESSMENT REPORT
          </p>
          <p
            className='leading-6 uppercase text-heading-3 font-Bold'
            style={{ color: textColour }}>
            {details.name}
          </p>
          {details.jobTitle && (
            <p className='text-base font-Medium text-Gray-600'>
              Applying for role of {details.jobTitle}
            </p>
          )}
        </div>
      </Row>
    </Column>
  )
}
