/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ExecutiveSummaryDto,
  useGetExecutiveSummaryList,
} from 'api/executiveSummary'
import CN from 'classnames'

import { UITable } from 'components/common/UITable'

import { getColumns } from './columns'
import { DeleteReportModal } from './DeleteReportModal'
import { ExecutiveSummaryReportViewModal } from './ExecutiveSummaryReportViewModal'

export type DownloadableReportsTableProps = {
  [x: string]: any
  candidateId: string
}

export const DownloadableRefCheckReportsTable = ({
  candidateId,
  candidateDetails,
}: DownloadableReportsTableProps) => {
  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  const [summary, setSummary] = useState<ExecutiveSummaryDto | null>(null)

  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false)
  const [showReportDeleteModal, setShowReportDeleteModal] = useState(false)

  const { clickValue } = useSelector((state: any) => state.layout)

  useEffect(() => {
    const toBeUpdateTableData = {
      ...tableData,
      reportDetails: tableData?.summary?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }
    setTableData(toBeUpdateTableData)
  }, [clickValue])

  const {
    refetch: refetchRepotTable,
    isLoading,
    isFetching,
  } = useGetExecutiveSummaryList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
      candidateId: candidateId,
    },
    (data: any) => {
      const toBeUpdateTableData = {
        ...data,
        summary: data?.summary?.map((item: any) => {
          return {
            ...item,
            showMore: false,
          }
        }),
      }

      setTableData(toBeUpdateTableData || {})
    },
  )

  useEffect(() => {
    refetchRepotTable()
  }, [paginationVariables])

  const tableColumns: any = useMemo(() => {
    if (tableData?.summary && tableData?.summary?.length > 0) {
      return getColumns(
        setSummary,
        setShowDownloadReportModal,
        setShowReportDeleteModal,
      )
    }
    return []
  }, [tableData])

  return (
    <>
      <div className='w-full h-full'>
        <div className='w-full h-[calc(90vh-80px)] relative '>
          <div
            className={CN(
              'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
            )}>
            <UITable
              data={tableData?.summary || []}
              isLoading={isLoading || isFetching}
              columns={tableColumns}
              allowRowSelection={false}
              isSorted={true}
              isManualSortBy={true}
              hasFooter={true}
              hasCheckBox={true}
              isHeader={false}
              isFilterEnabled={false}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
              totalRowCount={tableData?.totalCount || 0}
            />
          </div>
        </div>
        {summary && (
          <ExecutiveSummaryReportViewModal
            isModalActive={showDownloadReportModal}
            setIsModalActive={setShowDownloadReportModal}
            candidateId={candidateId}
            candidateDetails={candidateDetails}
            summaryId={summary.id}
          />
        )}
        {summary && (
          <DeleteReportModal
            isModalActive={showReportDeleteModal}
            setIsModalActive={setShowReportDeleteModal}
            selectedReportId={summary.id}
            refetchRepotTable={refetchRepotTable}
          />
        )}
      </div>
    </>
  )
}
