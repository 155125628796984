import CN from 'classnames'

type CandidateReferenceCheckSnackProps = {
  status: null | 'Failed' | 'Incomplete' | 'Passed' | 'NotSent'
}

export const CandidateReferenceCheckSnack = ({
  status,
}: CandidateReferenceCheckSnackProps) => {
  return (
    <span
      className={CN(
        'flex items-center justify-center py-[2px] pr-[10px] pl-[8px] gap-[6px] border-2 rounded-[10px] h-[20px] box-border',
        {
          'bg-Green-500 border-Green-500': status === 'Passed',
          'bg-Red-500 border-Red-500': status === 'Failed',
          'bg-[#F48602] border-[#F48602]': status === 'Incomplete',
          'bg-Gray-500 border-Gray-500': status === null || status == 'NotSent',
        },
      )}>
      <i
        className={CN(
          'flex items-center justify-center text-extra-small text-white',
          {
            'ri-checkbox-circle-line': status === 'Passed',
            'ri-close-circle-line': status === 'Failed',
            'ri-indeterminate-circle-line': status === 'Incomplete',
            'ri-mail-close-line': status === null || status == 'NotSent',
          },
        )}
      />
      <span className='text-extra-small font-Bold text-white'>
        {status === null || status == 'NotSent' ? 'Not Sent' : status}
      </span>
    </span>
  )
}
