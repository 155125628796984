import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useCreateCandidate } from 'api/candidates'
import {
  AppSettingKey,
  AppSettingProps,
  getAgencyAppSettings,
  INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  isSettingEnabled,
  updateSettingByKey,
} from 'features/settings'

import { PhoneInputField } from 'components/atoms'
import { Modal, ModalFooter } from 'components/molecules'
import { isEmail, isLetters, isPhoneNumber } from 'utils'

type AddCandidateAndNavigateModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  refreshCandidateList: () => void
  onSuccessCallback: (candidate: any) => void
  title: string
  description: string
}

export const AddCandidateAndNavigateModal = ({
  isOpen,
  setIsOpen,
  refreshCandidateList,
  title,
  description,
  onSuccessCallback,
}: AddCandidateAndNavigateModalProps) => {
  const notify = (props: any) => Toast(props)

  const { mutate: createCandidateMutate, isLoading: createCandidateIsLoading } =
    useCreateCandidate()

  const { userDetails } = useSelector((state: any) => state.user)

  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const [candidateSettings, setCandidateSettings] = useState<AppSettingProps[]>(
    INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  )

  const isEmailRequired = isSettingEnabled(
    AppSettingKey.IS_CANDIDATE_EMAIL_REQUIRED,
    agencyAppSettings,
  )

  const isSmsEnabled = isSettingEnabled(
    AppSettingKey.SmsFeature,
    agencyAppSettings,
  )

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    jobTitle: false,
  })

  /** candidate details and error fields state */
  const [candidateDetails, setCandidateDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: 'AU',
    dateOfBirth: '',
    gender: '',
    jobTitle: '',
    noOfReferences: 0,
    isEmployee: false,
    consultantId: userDetails?.id,
    candidateSettings: [] as any,
  })

  const [renderKey] = useState(Math.random())

  useEffect(() => {
    setCandidateSettings(agencyAppSettings)
  }, [agencyAppSettings])

  useEffect(() => {
    setCandidateDetails({
      ...candidateDetails,
      consultantId: userDetails?.id,
    })
  }, [userDetails])

  useEffect(() => {
    const updatedSettings = updateSettingByKey(
      AppSettingKey.SmsFeature,
      candidateSettings,
      {
        value: `true`,
      },
    )

    setCandidateDetails({
      ...candidateDetails,
      candidateSettings: updatedSettings,
    })
  }, [candidateSettings])

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** reset input fields */
  const resetInputFields = () => {
    setCandidateDetails({
      ...candidateDetails,
      firstName: '',
      lastName: '',
      email: '',
      countryCode: 'AU',
      phone: '',
      jobTitle: '',
      noOfReferences: 0,
      isEmployee: false,
    })
    setCandidateSettings(agencyAppSettings)
  }

  /** handle primary button disable */
  useEffect(() => {
    const { firstName, email, phone, jobTitle }: any = candidateDetails || {}

    if (isEmailRequired) {
      firstName && email && jobTitle
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    } else {
      firstName && phone && isSmsEnabled && jobTitle
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    }
  }, [candidateDetails])

  const validateInputs = () => {
    const { firstName, lastName, email, phone, jobTitle } = candidateDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) && firstName?.length < 100 ? false : true,
      lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
      jobTitle: jobTitle?.length < 100 ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      firstName?.length < 100 &&
      isLetters(lastName) &&
      lastName?.length < 100 &&
      isPhoneNumber(phone) &&
      phone?.length < 20 &&
      jobTitle?.length < 100

    if (isEmailRequired) {
      setIsErrorField({
        ...isErrorField,
        email: isEmail(email) && email?.length < 200 ? false : true,
        firstName:
          isLetters(firstName) && firstName?.length < 100 ? false : true,
        lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
        phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
        jobTitle: jobTitle?.length < 100 ? false : true,
      })
      return (
        isAllValidFields &&
        // isValidNoteCount &&
        email?.length < 200 &&
        isEmail(email)
      )
    }

    return isAllValidFields
  }

  /** Process the add new candidate */
  const addNewCandidate = () => {
    const valid = validateInputs()

    if (!valid)
      return notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })

    createCandidateMutate(
      {
        candidateDetails,
        referenceTypes: [],
        isAssessmentCandidate: true,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })
          resetInputFields()
          refreshCandidateList()
          onSuccessCallback(successData)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <Modal
        isActive={isOpen}
        headerTitle={title}
        subHeaderTitle={description}
        size='md'
        className='!z-[150]'
        isHeaderShow={true}
        isFooterShow={true}
        modalProps={{
          style: { maxHeight: '95vh' },
        }}
        onHeaderCloseButtonClick={() => closeModal()}>
        <div className='flex flex-col w-full justify-start h-[650px] overflow-y-auto p-4 bg-Gray-50 styled-scroll'>
          <div className='w-full px-2'>
            {/* first name section */}
            <TextField
              label='First Name'
              className='mt-5'
              isRequired
              value={candidateDetails?.firstName}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  firstName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.firstName}
            />
            {/* last name section */}
            <TextField
              label='Last Name'
              isRequired
              className='mt-5 mb-4'
              value={candidateDetails?.lastName}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  lastName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.lastName}
            />

            {/* email section */}
            <TextField
              label='Email'
              isRequired={isEmailRequired}
              className='mt-4'
              value={candidateDetails?.email}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  email: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isEmailRequired && isErrorField.email}
            />
            {/** Phone number section */}
            <PhoneInputField
              key={renderKey}
              label='Phone'
              isRequired
              value={candidateDetails?.phone}
              onChangeWithCountry={(number: any, countryCode: string) =>
                setCandidateDetails({
                  ...candidateDetails,
                  phone: number,
                  countryCode,
                })
              }
              className='mt-5'
              isErrored={isErrorField.phone}
            />
            {/* job title section */}
            <TextField
              label='Role candidate is applying for'
              className='mt-5'
              isRequired
              value={candidateDetails?.jobTitle}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  jobTitle: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.jobTitle}
            />
          </div>
        </div>

        <ModalFooter className='border-white border-t-0 shadow-none px-6'>
          <div className='flex flex-col flex-1 gap-y-2'>
            <Button
              isDisabled={isPrimaryButtonDisabled}
              isLoading={createCandidateIsLoading}
              onClick={() => addNewCandidate()}
              theme='cobalt'>
              Next
            </Button>
            <Button
              isDisabled={createCandidateIsLoading}
              onClick={() => closeModal()}
              textTheme='blue'
              theme='white'>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
