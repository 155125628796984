import React from 'react'
import { Button } from '@labourhub/labour-hub-ds'

import { Modal, ModalFooter } from 'components/molecules/Modal'

// import '../styles.scss'

type AlertModalProps = {
  isOpen: boolean
  assessment: {
    name: string
    sentOn: string
    sentBy: string
    dueDate: string
  }
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handlePrimaryAction: () => void
  handleSecondaryAction?: () => void
}

const AssessmentDetail = ({
  label,
  value,
}: {
  label: string
  value: string
}) => (
  <div className='flex justify-between mb-2 w-full'>
    <span className='text-Gray-600'>{label}</span>
    <span className='text-Gray-800 font-SemiBold'>{value}</span>
  </div>
)

export const ResendAssessmentConfirmationModal = ({
  isOpen,
  assessment,
  setIsOpen,
  handlePrimaryAction,
  handleSecondaryAction,
}: AlertModalProps) => {
  const handleSecondaryButtonClick = () => {
    if (handleSecondaryAction) {
      handleSecondaryAction()
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Modal
      isActive={isOpen}
      headerTitle={''}
      size='md'
      className='!z-[150]'
      isFooterShow={true}
      isPrimaryButtonDisable={false}
      onHeaderCloseButtonClick={() => {
        setIsOpen(false)
      }}>
      <div className='flex flex-col items-center justify-center p-6 pt-0'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-[#FFF6EC]'>
          <i className='ri-error-warning-line text-[#DD7F0B] text-heading-3'></i>
        </div>
        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Resend Assessment Request
        </span>
        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          This assessment request has already been sent. Resending it will use
          another credit. Would you like to proceed?
        </span>
        <div className='mt-4 w-full'>
          <AssessmentDetail label='Assessment Name' value={assessment.name} />
          <AssessmentDetail label='Sent on' value={assessment.sentOn} />
          <AssessmentDetail label='Sent by' value={assessment.sentBy} />
          <AssessmentDetail label='Due Date' value={assessment.dueDate} />
        </div>
      </div>

      <ModalFooter className='border-t-0 shadow-none bg-Gray-50'>
        <div className='flex flex-1 gap-2 justify-end'>
          <Button
            onClick={() => handleSecondaryButtonClick()}
            textTheme='blue'
            className='w-1/3'
            theme='white'>
            Close
          </Button>
          <Button
            onClick={handlePrimaryAction}
            className='w-1/3'
            theme='cobalt'>
            I&apos;m Sure
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
