import { Button } from '@labourhub/labour-hub-ds'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'

import { Card } from 'components/atoms/Card'
import { timeToLocalTimeZoneWithISOFormat2 } from 'utils'

import { CreditListItem } from './CreditListItem'

type AssessmentCompletedDetailsProps = {
  title?: string
  setShowDownlaodAssessmentReportModal: React.Dispatch<
    React.SetStateAction<boolean>
  >
  reportViewData: any
  isReport: boolean
}

const AssessmentCompletedDetails = ({
  title = '',
  reportViewData,
  isReport,
  setShowDownlaodAssessmentReportModal,
}: AssessmentCompletedDetailsProps) => {
  return (
    <Card>
      {!isReport && (
        <div className='flex items-center justify-between'>
          <div className='text-heading-5 font-SemiBold flex gap-2 items-center'>
            {`${title} Assessment`}{' '}
            <img
              src={difficultyImages[reportViewData.difficultyLevel]}
              alt='difficulty-img'
            />
          </div>
          <Button
            className='h-8'
            onClick={() => {
              setShowDownlaodAssessmentReportModal(true)
            }}>
            Download Report
          </Button>
        </div>
      )}

      <div className='mt-3'>
        {!isReport && (
          <CreditListItem
            title='Sent on:'
            data={timeToLocalTimeZoneWithISOFormat2(reportViewData.sentOn)}
          />
        )}

        <CreditListItem
          title='Completed on:'
          data={timeToLocalTimeZoneWithISOFormat2(reportViewData.completedOn)}
        />
        <CreditListItem title='Finished in:' data={reportViewData.finishedIn} />
        <CreditListItem
          title='Mouse out of screen:'
          data={reportViewData.mouseOutOfScreen}
        />
        {isReport && (
          <CreditListItem
            title='Status:'
            data={reportViewData.candidateStage}
          />
        )}
      </div>
    </Card>
  )
}

export default AssessmentCompletedDetails
