// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { ForwardedRef, Fragment } from 'react'
import JSONForm from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'

import { Rate } from 'components/atoms'
import IFrameVideo from 'components/atoms/IFrameVideo/IFrameVideo'
import ImageViewer from 'components/atoms/ImageViewer/ImageViewer'

import CustomTemplate from './CustomTemplate'

import './theme.scss'

export type FormBuilderCustomProps = {
  schema: any
  formData?: any
  uiSchema: any
  buttonRef?: React.MutableRefObject<JSONForm<any, any> | null>
  onChange?: (event: any) => void
  onSubmit?: (event: any) => void
  onError?: (event: any) => void
  onFocus?: (event: any) => void
  readonly?: boolean
  [x: string]: any
}
export const FormBuilderCustom = React.forwardRef(
  (
    {
      schema,
      formData,
      uiSchema,
      onChange,
      onSubmit,
      onFocus,
      onError,
      readonly,
    }: FormBuilderCustomProps,
    ref: ForwardedRef<JSONForm<any, any>>,
  ) => {
    const Rating = (props: any) => {
      const imageUrl = props.schema?.properties?.imageurl?.description || ''
      const videoUrl = props.schema?.properties?.videourl?.description || ''
      return (
        <div className='w-full flex gap-3 items-center' id='appendinContainer'>
          {imageUrl && <ImageViewer imageUrl={imageUrl} />}
          {videoUrl && <IFrameVideo link={videoUrl} />}
          <div
            className={`${
              imageUrl || videoUrl ? 'w-1/2' : 'w-full'
            } append-class-container`}>
            <Rate
              count={props.schema.enum.length}
              rating={props.value ? props.value : undefined}
              onRating={(e) => {
                props.onChange(e.toString())
              }}
              id={props.id}
            />
          </div>
        </div>
      )
    }

    const widgets = {
      rating: Rating,
    }

    const changeSchema = (schema: any) => {
      const properties = { ...schema.properties }
      let tempIndex = 0
      Object.keys(properties).map((key: string) => {
        const property = properties[key]
        if (property?.type !== 'object') {
          properties[key] = {
            ...property,
            title: `${
              tempIndex < 9 ? `0${tempIndex + 1}.` : `${tempIndex + 1}.`
            } ${property.title}`,
          }
          tempIndex++
        }
      })

      return {
        ...schema,
        properties,
      }
    }

    return (
      <JSONForm
        showErrorList={false}
        widgets={widgets}
        className='jason-theme'
        schema={changeSchema(schema)}
        formData={formData}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        onChange={onChange}
        onFocus={onFocus}
        onError={onError}
        validator={validator}
        ref={ref}
        templates={{ FieldHelpTemplate: CustomTemplate }}
        readonly={readonly}>
        <Fragment />
      </JSONForm>
    )
  },
)
