import { ForwardedRef, forwardRef, useMemo } from 'react'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'

import { Column } from 'components/atoms/Column'
import AntiCheatinMonitor from 'components/molecules/AssesmentPreviewModal/AntiCheatinMonitor'
import AutoMarkingProgress from 'components/molecules/AssesmentPreviewModal/AutoMarkingProgress'
import { AssesmentAnswerRatingView } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssesmentAnswerRatingView'
import AssessmentCompletedSection from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentCompletedSection'

import { ReportBanner, ReportFooter, ReportLogoHeader } from '../../sections'
import {
  AssessmentReportCandidateDetails,
  AssessmentReportCandidateDetailsDto,
} from '../../sections/ReportCandidateDetails/AssessmentReportCandidateDetails'

import './style.scss'

type AssessmentReportPrintPreviewProps = {
  candidateDetails: CandidateProfileBasicDetailsType
  previewData: assessmentReportPreviewData | undefined
  dynamicFieldMapping: any
  changeTextSchema: any
  isMappingVariablesLoading?: boolean
  reportViewData: any
  markingValue: any
}

const getQuestionsPerPage = () => {
  return 2
}

const chunkArray = (array: any[], size: number) => {
  const result: any[] = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

export const AssessmentReportPrintPreview = forwardRef(
  (
    {
      candidateDetails,
      previewData,
      reportViewData,
      markingValue,
    }: AssessmentReportPrintPreviewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const candidateReportDetails = useMemo(() => {
      const details: AssessmentReportCandidateDetailsDto = {
        name: candidateDetails.name,
        candidateNo: candidateDetails.candidateNo,
        email: candidateDetails.email,
        phoneNumber: candidateDetails.phone,
        avatarUrl: candidateDetails.avatarUrl,
        jobTitle: candidateDetails.jobTitle,
        consultantName: candidateDetails.consultantName,
      }
      return details
    }, [candidateDetails])

    const questionsPerPage = useMemo(() => getQuestionsPerPage(), [])
    const reportChunks = useMemo(
      () => chunkArray(reportViewData.reportQuestion, questionsPerPage),
      [reportViewData.reportQuestion, questionsPerPage],
    )

    return (
      <Column className='hidden bg-white a4-preview w-[794px] pointer-events-none'>
        <div ref={ref} className='w-full h-full p-0 m-0'>
          <div style={{ paddingBottom: '20px' }}>
            <div className='h-[1123px] relative'>
              <ReportLogoHeader
                clientlogo={previewData?.clientLogoUrl}
                hasClientLogo={previewData?.hasClientLogo}
                hasCompanyLogo={previewData?.hasCompanyLogo}
              />
              <ReportBanner />
              <AssessmentReportCandidateDetails
                details={candidateReportDetails}
              />
              <div className='h-auto pb-4'>
                <AutoMarkingProgress
                  values={markingValue}
                  isReport={true}
                  totalMarks={reportViewData?.assessmentAutoMarking?.totalMarks}
                />
              </div>
              <div className='h-auto pb-4'>
                <AssessmentCompletedSection reportViewData={reportViewData} />
              </div>
              <div className='h-auto pb-4'>
                <AntiCheatinMonitor
                  reportViewData={reportViewData}
                  isReport={true}
                />
              </div>
            </div>
          </div>
          <div className='px-6 font-Medium text-base'>RESPONSES</div>
          {reportChunks.map((chunk, index) => (
            <div
              key={index}
              className='relative'
              style={{ paddingTop: '50px', paddingBottom: '50px' }}>
              <div className='relative'>
                <div className='px-6'>
                  {chunk.map((item, idx) => (
                    <AssesmentAnswerRatingView
                      selectedTemplate={item}
                      key={idx}
                      isPrint={true}
                    />
                  ))}
                </div>
              </div>
              {/* Add a page break after each chunk except the last one */}
              {index < reportChunks.length - 1 && (
                <div style={{ pageBreakAfter: 'always' }} />
              )}
            </div>
          ))}

          <ReportFooter isFixed={true} />
        </div>
      </Column>
    )
  },
)
