import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentSendList } from 'api/assessment/getAssessmentSendList'
import CN from 'classnames'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { CreditAlertModal } from 'features/payments/components/CreditAlertModal'
import { ZeroCreditAlertModal } from 'features/payments/components/ZeroCreditAlertModal'
import { getSubscriptionStore } from 'features/subscriptions'

import { PageLoader } from 'components/atoms'
import { TableHeader } from 'components/common/UITable'
import { UITablePagination } from 'components/common/UITable/UITablePagination'

import AssessmentResult from './AssessmentTable/AssessmentResult'
import { BuildNewTemplateCard } from './BuildNewTemplateCard'
import { CustomAssessmentCardItem } from './CustomSurveyCardItem'
import { SearchHeader } from './SearchHeader'
import TabButton from './TabButton'

type CustomAssessmentTabProps = {
  candidateId: string
  candidateDetails: any
}
export const CustomAssessmentTab = ({
  candidateId,
  candidateDetails,
}: CustomAssessmentTabProps) => {
  const notify = (props: any) => Toast(props)
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const assessmentId = searchParams.get('assessment_id')
  const assessmentResultsTab = searchParams.get('assessment_tab')

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [tableData, setTableData] = useState<any>({
    assessment: [],
    message: '',
    messageTitle: null,
    skip: 0,
    take: 0,
    totalCount: 0,
  })
  const initialPagination = {
    skip: tableData.skip || 0,
    take: 3,
    search: undefined,
    sortColumnName: null,
    sortDirection: null,
    difficultyLevel: '',
    type: '',
  }

  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(1)
  const [paginationVariables, setPaginationVariables] =
    useState<any>(initialPagination)

  useEffect(() => {
    if (pageNumber !== 1) {
      if (pageNumber === 2) {
        setPaginationVariables((prev) => ({
          ...prev,
          skip: prev.skip,
          take: 4,
        }))
      } else {
        setPaginationVariables((prev) => ({
          ...prev,
          skip: prev.skip - 1,
          take: 4,
        }))
      }
    } else {
      setPaginationVariables((prev) => ({
        ...prev,
        take: 3,
      }))
    }
  }, [pageNumber])

  const [tabId, setTabId] = useState<any>(
    assessmentId || assessmentResultsTab === 'results' ? 2 : 1,
  )

  useEffect(() => {
    if (tabId !== 1) {
      setPageNumber(1)
      setPaginationVariables(initialPagination)
    }
  }, [tabId])

  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  const {
    refetch: refetchAssessmentTable,
    isLoading: getAssessmentTableIsLoading,
    isFetching: getAssessmentTableIsFetching,
  } = useGetAssessmentSendList(
    {
      candidateId: candidateId,
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search || '',
      type: paginationVariables.type,
      difficultyLevel: paginationVariables.difficultyLevel,
    },
    onSuccess,
    onError,
  )
  useEffect(() => {
    refetchAssessmentTable()
  }, [])

  /** on change search */
  useEffect(() => {
    const timer = setTimeout(() => {
      searchKeyWord &&
        setPaginationVariables({
          ...paginationVariables,
          skip: 0,
          search: searchKeyWord,
        })
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchKeyWord])

  const [hideWarningMessage, setHideWarningMessage] = useState(false)

  const [
    isAssessmentLowCreditAlertModalActive,
    setIsAssessmentLowCreditAlertModalActive,
  ] = useState(true)

  const [
    isAssessmentZeroCreditAlertModalActive,
    setIsAssessmentZeroCreditAlertModalActive,
  ] = useState(true)

  const { remainingCredit } = useSelector(getSubscriptionStore)

  const isAssessmentZeroWarningExist = localStorage.getItem(
    'isAssessmentZeroWarning',
  )

  const isAssessmentOneWarningExist = localStorage.getItem(
    'isAssessmentOneWarning',
  )

  useEffect(() => {
    setHideWarningMessage(
      localStorage.getItem('isAssessmentZeroWarning') === 'true' ? true : false,
    )
    setHideWarningMessage(
      localStorage.getItem('isAssessmentOneWarning') === 'true' ? true : false,
    )
  }, [localStorage])

  return (
    <>
      {isAssessmentZeroWarningExist === 'true' &&
        hideWarningMessage &&
        remainingCredit == 0 && (
          <div className='flex w-full p-4 mb-6 border rounded bg-Yellow-50 border-Yellow-300'>
            <i className='mr-2 ri-alert-fill text-Yellow-600 text-base w-[20px] h-[20px]'></i>

            <div className='flex justify-between w-full body-container'>
              <div className='flex flex-col body-left-container'>
                <div className='w-full font-Medium text-base text-Yellow-900'>
                  Credits
                </div>

                <div className='flex flex-col pt-1 font-Regular text-Yellow-700  text-base'>
                  You currently have no credits available. To send an
                  assessment, please purchase additional credits.
                  <Button
                    className='mt-3 w-fit h-[30px] text-extra-small font-Medium'
                    onClick={() => {
                      dispatch(setPayAsYouGoCreditsModalActive(true))
                    }}>
                    Buy Credits
                  </Button>
                </div>
              </div>

              <div>
                <Button
                  className='outline-none active:outline-none'
                  theme='link'
                  onClick={() => {
                    localStorage.setItem('isAssessmentZeroWarning', 'false')
                    setHideWarningMessage(false)
                  }}>
                  <i className='leading-6 ri-close-line text-small text-Yellow-600'></i>
                </Button>
              </div>
            </div>
          </div>
        )}

      {isAssessmentOneWarningExist === 'true' &&
        hideWarningMessage &&
        remainingCredit == 1 && (
          <div className='flex w-full p-4 mb-6 border rounded bg-Blue-50 border-Blue-300'>
            <i className='mr-2 ri-information-fill text-Blue-600 text-base w-[20px] h-[20px]'></i>

            <div className='flex justify-between w-full body-container'>
              <div className='flex flex-col body-left-container'>
                <div className='w-full font-Medium text-base text-Blue-800'>
                  Only 1 Credit Remaining
                </div>

                <div className='flex flex-col pt-1 font-Regular text-Blue-700  text-base'>
                  You only have 1 credit remaining. To send more assessments,
                  buy additional credits.
                  <Button
                    className='mt-3 w-fit h-[30px] text-extra-small font-Medium'
                    onClick={() => {
                      dispatch(setPayAsYouGoCreditsModalActive(true))
                    }}>
                    Buy Credits
                  </Button>
                </div>
              </div>

              <div>
                <Button
                  className='outline-none active:outline-none'
                  theme='link'
                  onClick={() => {
                    localStorage.setItem('isAssessmentOneWarning', 'false')
                    setHideWarningMessage(false)
                  }}>
                  <i className='leading-6 ri-close-line text-small text-Blue-600'></i>
                </Button>
              </div>
            </div>
          </div>
        )}
      <TabButton setTabId={setTabId} tabId={tabId} />
      {tabId === 1 ? (
        <>
          <div className='flex flex-col px-4 w-full bg-white border rounded-t-lg outline-none border-Gray-100 mb-4'>
            <TableHeader
              isFilterEnable={true}
              filterActive={false}
              searchKeyWord={searchKeyWord}
              setSearchKeyWord={setSearchKeyWord}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
              searchFieldPlaceholder={'Search assessment name'}
              setIsFilterMenuOpen={setIsFilterMenuOpen}
              isFilterMenuOpen={isFilterMenuOpen}
            />
            <SearchHeader
              isFilterMenuOpen={isFilterMenuOpen}
              setPaginationVariables={setPaginationVariables}
            />
          </div>

          {(getAssessmentTableIsLoading || getAssessmentTableIsFetching) && (
            <div
              className={CN(
                'absolute top-[45px] left-0 flex items-center justify-center bg-Gray-500 bg-opacity-20 w-full h-[calc(100%-100px)] cursor-not-allowed z-[3]',
              )}>
              <PageLoader size='xxs' className='bg-opacity-100' />
            </div>
          )}
          <div className='grid grid-cols-2 gap-4'>
            {pageNumber === 1 && <BuildNewTemplateCard />}
            {!getAssessmentTableIsLoading &&
              tableData.assessment.map((assessment, index) => (
                <CustomAssessmentCardItem
                  key={index}
                  assessment={assessment}
                  refetch={refetchAssessmentTable}
                />
              ))}
          </div>
          <UITablePagination
            data={tableData?.assessment || []}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            defaultPageSize={4}
            firstPageCustomCardCount={1}
          />
        </>
      ) : (
        <AssessmentResult
          candidateDetails={candidateDetails}
          candidateId={candidateId}
        />
      )}
      {remainingCredit == 1 && (
        <CreditAlertModal
          isModalActive={isAssessmentLowCreditAlertModalActive}
          setIsModalActive={setIsAssessmentLowCreditAlertModalActive}
        />
      )}

      {remainingCredit == 0 && (
        <ZeroCreditAlertModal
          isModalActive={isAssessmentZeroCreditAlertModalActive}
          setIsModalActive={setIsAssessmentZeroCreditAlertModalActive}
        />
      )}
    </>
  )
}
