import { useEffect, useState } from 'react'

export type RemainingCreditsBadgeProps = {
  totalQuota: number
  remainingQuota: number
}

export const RemainingCreditsBadge = ({
  remainingQuota,
  totalQuota,
}: RemainingCreditsBadgeProps) => {
  const [quotaValue, setQuotaValue] = useState(remainingQuota)

  useEffect(() => {
    setQuotaValue(remainingQuota > 0 ? Math.min(remainingQuota, totalQuota) : 0)
  }, [totalQuota, remainingQuota])

  return (
    <>
      <span className='text-Gray-800 font-Bold text-heading-5'>
        {quotaValue}
      </span>
    </>
  )
}
