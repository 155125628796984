import { useDispatch } from 'react-redux'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { QuotaItem } from 'features/subscriptions'

import { SubscriptionUpgradeBadge } from 'components/atoms'

type PoliceCheckPlanBadgeProps = {
  policeCheck: QuotaItem
  volunteerCheck: QuotaItem
}

export const PoliceCheckPlanBadge = ({
  policeCheck,
  volunteerCheck,
}: PoliceCheckPlanBadgeProps) => {
  const dispatch = useDispatch()

  const isStdEmptyOrPartial = !(
    policeCheck.purchasedQuota > 0 &&
    policeCheck.remainingQuota > 0 &&
    policeCheck.purchasedQuota === policeCheck.remainingQuota
  )
  const isVolunteerEmptyOrPartial = !(
    volunteerCheck.purchasedQuota > 0 &&
    volunteerCheck.remainingQuota > 0 &&
    volunteerCheck.purchasedQuota === volunteerCheck.remainingQuota
  )

  const showPoliceCheckBuyBtn = isStdEmptyOrPartial || isVolunteerEmptyOrPartial

  const totalPoliceCheckQuota =
    policeCheck.purchasedQuota + volunteerCheck.purchasedQuota

  const remainingPoliceCheckQuota =
    policeCheck.remainingQuota + volunteerCheck.remainingQuota

  return (
    <SubscriptionUpgradeBadge
      totalQuota={totalPoliceCheckQuota}
      remainingQuota={remainingPoliceCheckQuota}
      isActionBtnRequired={showPoliceCheckBuyBtn}
      onClickActionBtn={() => dispatch(setPayAsYouGoCreditsModalActive(true))}
      label='Remaining Credits'
      btnTitle='Buy'
    />
  )
}
