type MultiLableTextAreaProps = {
  firstLabel: string
  secondLabel: string
  id: string
  rows?: number
  placeholder?: string
  value: string
  onChange: (x: string) => void
  isRequired?: boolean
  maxLength?: number
  showCount?: boolean
  setHasReachedMaxChars?: React.Dispatch<React.SetStateAction<boolean>>
}

export const MultiLableTextArea = ({
  firstLabel,
  secondLabel,
  id,
  rows = 3,
  placeholder,
  value,
  onChange,
  isRequired = false,
  maxLength = 250,
  showCount = false,
  setHasReachedMaxChars,
}: MultiLableTextAreaProps) => {
  // useEffect(() => {
  //   setCharCount(value.length)
  //   setHasError(value.length >= maxLength)
  //   setHasReachedMaxChars && setHasReachedMaxChars(value.length >= maxLength)
  // }, [value, maxLength])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    // if (newValue.length <= maxLength) {
    //   setCharCount(newValue.length)
    onChange(newValue)
    // }
  }

  return (
    <div className='mt-8'>
      <label
        htmlFor={id}
        className='block mb-2 text-sm font-Medium text-Gray-800'>
        {firstLabel}
        {isRequired && (
          <span className='text-Red-500 pl-[2px] font-Bold'>*</span>
        )}
        <div className='text-Gray-500 font-Regular text-small'>
          {secondLabel}
        </div>
      </label>
      <textarea
        // Hide Max Count functionalities
        // className={`border ${
        //   hasError ? 'border-Red-500' : 'border-Gray-300'
        // } text-black text-sm rounded-md block w-full p-2`}
        // maxLength={maxLength}
        onChange={handleChange}
        className={
          'border border-Gray-300 text-black text-sm rounded-md block w-full p-2'
        }
        id={id}
        rows={rows}
        placeholder={placeholder}
        autoComplete='none'
        value={value}
      />
      {/* Hide Max Count functionalities */}
      {/* <div className='flex justify-between text-sm'>
        <span
          className={`text-left ${
            hasError ? 'text-Red-500' : 'text-Gray-400'
          } text-[12px]`}>
          {hasError
            ? 'Character limit exceeded'
            : `Maximum ${maxLength} characters`}
        </span>

        {showCount && (
          <span className='text-right text-Gray-400 text-[12px]'>
            Character Count: {charCount}/{maxLength}
          </span>
        )}
      </div> */}
    </div>
  )
}
