import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import ImageUploader from 'assets/images/ImageUploader.png'
import CN from 'classnames'
import { getAgency } from 'features/agencies/store/selectors'

import { getCroppedImg } from 'components/molecules/CustomizableEditProfilePictureModal/ImageCropAndScale'

type CompanyProfilePictureProps = {
  imageFile: any
  setImageFile: any
  isRectangleLogo?: boolean
}

export const CompanyProfilePicture = ({
  imageFile,
  setImageFile,
  isRectangleLogo = false,
}: CompanyProfilePictureProps) => {
  const { agency }: any = useSelector(getAgency)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [newUploadPanel, setNewUploadPanel] = useState(true)
  const [isDisabledResetImageButton, setIsDisabledResetImageButton] =
    useState(true)
  const [warningMessage, setWarningMessage] = useState('')
  const [droppedFile, setDroppedFile] = useState<any>()

  useEffect(() => {
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    setNewUploadPanel(imageUrl || agency.logoUrl ? false : true)
  }, [imageUrl])

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const onCropComplete = async (_, croppedAreaPixels) => {
    if (imageUrl && zoom !== 1.01) {
      const croppedImage = await getCroppedImg(
        isRectangleLogo,
        imageUrl,
        croppedAreaPixels,
      )
      setImageFile(croppedImage)
    }
  }

  const onZoomChange = (e: any) => {
    setIsDisabledResetImageButton(false)
    setZoom(e.target.value)
  }

  const onZoomChangeCrop = (zoom) => {
    setIsDisabledResetImageButton(false)
    setZoom(zoom)
  }

  /** handle image upload file on drop */
  const onDrop = (accepted) => {
    if (
      accepted[0].type === 'image/jpeg' ||
      accepted[0].type === 'image/png' ||
      accepted[0].type === 'image/jpg'
    ) {
      setDroppedFile(accepted)
      addFile(accepted)
      setWarningMessage('')
    } else {
      const message = 'Please submit valid file type (jpg, png, jpeg)'
      setWarningMessage(message)
    }
  }

  const addFile = (file: any) => {
    setNewUploadPanel(false)
    file.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setImageFile(file)
      setImageUrl(file.preview)
    })
  }

  useEffect(() => {
    if (zoom === 1.01) {
      addFile(droppedFile)
    }
  }, [zoom])

  return newUploadPanel ? (
    <div className='w-full'>
      <span className='text-small font-Medium text-Gray-800'>
        Change Primary Logo
      </span>
      <div className='w-full mt-2 border-2 border-dashed rounded-md border-Gray-300'>
        <Dropzone
          key={Math.random()}
          multiple={false}
          onDrop={(accepted) => {
            onDrop(accepted)
          }}>
          {({ getRootProps, getInputProps }) => (
            <div id={Math.random().toString()}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  className={CN(
                    'flex flex-col justify-center items-center px-4 py-5',
                  )}>
                  <img
                    src={ImageUploader}
                    className={CN({
                      'w-[48px]': !isRectangleLogo,
                      'w-[48px] object-contain': isRectangleLogo,
                    })}
                    alt='drag-and-drop-area'
                  />

                  <div className='flex-row'>
                    <span className='cursor-pointer text-small text-Cobalt-600 font-Medium'>
                      Upload a file
                    </span>
                    <span className='ml-1 text-small text-Gray-600'>
                      or drag and drop
                    </span>
                  </div>

                  <span className='w-[360px] text-center text-extra-small text-Gray-500 pt-1'>
                    {!isRectangleLogo
                      ? `To get the best results make sure your image is squared one.
width and height should be identical or 1:1 ratio`
                      : `To get the best results make sure your image is rectangular one. 
                            Ratio for width and height should be 5:1 or similar.`}
                  </span>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>

      {warningMessage && (
        <div className='flex items-center justify-start w-full py-2 bg-Red-50'>
          <i className='ri-error-warning-line text-Red-500 mr-[11.6px] text-heading-4 ml-6' />
          <span className='text-small text-Red-600 font-Regular'>
            {warningMessage}
          </span>
        </div>
      )}
    </div>
  ) : agency.logoUrl && !imageUrl ? (
    <div className='relative'>
      <div className='flex justify-center items-center w-[132px] h-[132px] rounded-full object-cover overflow-hidden'>
        <img src={agency.logoUrl} alt='' className='object-contain w-full' />

        <div className='pl-[84px] pt-[84px] absolute'>
          <button
            className='flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md cursor-pointer focus:outline-none'
            onClick={() => setNewUploadPanel(true)}>
            <i className='ri-pencil-line text-Cobalt-500 text-heading-5' />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className='relative'>
        <div className='relative'>
          <span className='text-small font-Medium text-Gray-800'>
            Change Primary Logo
          </span>
          <div
            className={CN('crop-container mt-2 relative', {
              'h-[130px] w-[360px]': isRectangleLogo,
              'h-56 w-56 ': !isRectangleLogo,
            })}>
            {!newUploadPanel && imageUrl && (
              <Cropper
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                aspect={!isRectangleLogo ? 1 : 4 / 1}
                cropShape={!isRectangleLogo ? 'round' : 'rect'}
                showGrid={false}
                restrictPosition={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChangeCrop}
              />
            )}
          </div>
        </div>
        <div className='absolute top-[29px] right-[260px]'>
          <Button
            icon={<i className='ri-delete-bin-line text-Cobalt-600' />}
            size='sm'
            isIconOnly
            className='mb-2'
            onClick={() => {
              setCrop({ x: 0, y: 0 })
              setImageFile(null)
              setNewUploadPanel(true)
            }}
            textTheme='blue'
            theme='white'
          />
          <Button
            icon={
              <i
                className={CN('ri-refresh-line', {
                  'text-Cobalt-600': !isDisabledResetImageButton,
                  'text-Gray-400': isDisabledResetImageButton,
                })}
              />
            }
            isDisabled={isDisabledResetImageButton}
            size='sm'
            isIconOnly
            onClick={() => {
              setCrop({ x: 0, y: 0 })
              setZoom(1.01)
              setIsDisabledResetImageButton(true)
            }}
            textTheme='blue'
            theme='white'
          />
        </div>
      </div>

      {/* image scaler */}

      <div className='scaler flex mt-3 w-[224px]'>
        <i className='ri-indeterminate-circle-fill text-Gray-400' />
        <input
          className='w-full mx-3'
          name='scale'
          type='range'
          onChange={onZoomChange}
          min='0.1'
          max='2'
          value={zoom}
          step='0.01'
        />
        <i className='ri-add-circle-fill text-Gray-400' />
      </div>
    </>
  )
}
