import { useEffect, useRef, useState } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentReportViewById } from 'api/assessment/getAssessmentReportViewById'
import { useGetRealtimeAssessmentRating } from 'api/assessment/getRealtimeAssessmentRating'
import { useUpdateAssessmentComment } from 'api/assessment/updateAssessmentComment'
import { ModalProps } from 'types'

import { updateNode } from 'utils/updateNode'

import { Modal, ModalFooter } from '../Modal'

import { AssessmentDownloadModal } from './DownloadReport/AssessmentDownloadModal'
import RatingTable from './RatingTable/RatingTable'
import AntiCheatinMonitor from './AntiCheatinMonitor'
import AssessmentCompletedDetails from './AssessmentCompletedDetails'
import AutoMarkingProgress from './AutoMarkingProgress'
import CandidateStage from './CandidateStage'

type AssesmentPreviewModalReportProps = ModalProps & {
  candidateDetails: any
  templateId: any
  responseId: string
  refreshAssessmentList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>
}

export const AssesmentPreviewModalReport = ({
  isModalActive,
  setIsModalActive,
  templateId,
  candidateDetails,
  refreshAssessmentList,
  responseId,
}: AssesmentPreviewModalReportProps) => {
  const notify = (props: any) => Toast(props)
  const [reportViewData, setReportViewData] = useState<any>(null)
  const [jsonSchema, setJsonSchema] = useState<any>()
  const [totalMarks, setTotalMarks] = useState<any>()
  const [markingValue, setMarkingValue] = useState<any>({
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  })
  const [searchParams] = useSearchParams()
  const candidateId: string = searchParams.get('candidate_id') || ''
  /** get dynamic form */
  //   const {
  //     data: dynamicForm,
  //     refetch: getDynamicForm,
  //     isLoading: getDynamicFormIsLoading,
  //   } = useGetDynamicFormAssessment(templateId)
  const [
    showDownlaodAssessmentReportModal,
    setShowDownlaodAssessmentReportModal,
  ] = useState(false)

  const {
    refetch: getAssessmentReportView,
    isLoading: assessmentReportViewLoading,
    isFetching: assessmentReportViewFetching,
  } = useGetAssessmentReportViewById(
    {
      candidateId: candidateId,
      assessmentId: templateId,
      responseId: responseId,
    },
    async (data: any) => {
      setReportViewData(data?.assessmentReport)
      setJsonSchema(data?.assessmentReport?.jsonSchema)
      setMarkingValue({
        correct: data?.assessmentReport?.assessmentAutoMarking?.correct || 0,
        partiallyCorrect:
          data?.assessmentReport?.assessmentAutoMarking?.partiallyCorrect || 0,
        incorrect:
          data?.assessmentReport?.assessmentAutoMarking?.incorrect || 0,
        notAnswered:
          data?.assessmentReport?.assessmentAutoMarking?.notAnswered || 0,
      })
      setTotalMarks(data?.assessmentReport?.assessmentAutoMarking?.totalMarks)
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )
  const componentRef = useRef<HTMLDivElement>(null)

  const [candidateStage, setCandidateStage] = useState('')

  useEffect(() => {
    if (templateId && isModalActive) {
      getAssessmentReportView()

      //   getDynamicForm()
    }
  }, [isModalActive])

  useEffect(() => {
    updateNode(componentRef)
  }, [isModalActive, templateId])

  const handleCloseModals = () => {
    setIsModalActive(false)
    setShowDownlaodAssessmentReportModal(false)
  }

  const { mutate: updateAssessmentComment, isLoading: questionsUpdateLoading } =
    useUpdateAssessmentComment()
  const handleUpdate = () => {
    if (reportViewData?.assessmentId) {
      updateAssessmentComment(
        {
          AssessmentId: reportViewData?.assessmentId,
          assessmentResponseId: reportViewData?.assessmentResponseId,
          CandidateId: candidateId,
          jsonSchema: jsonSchema,
          candidateStage: candidateStage || reportViewData.candidateStage,
        },
        {
          onSettled: () => {
            Toast({
              alertHeader: 'Comment updated successfully',
              alertBody: '',
              status: 'Success',
            })
            refreshAssessmentList()
            getAssessmentReportView()
            setIsModalActive(false)
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    }
  }

  //   const {
  //     refetch: getRealtimeAssessmentRating,
  //     isLoading: isRealtimeAssessmentRatingLoading,
  //   } = useGetRealtimeAssessmentRating(
  //     {
  //       candidateId: candidateId,
  //       assessmentId: templateId,
  //       jsonSchema,
  //       assessmentResponseId: reportViewData?.assessmentResponseId,
  //       candidateStage: candidateStage,
  //     },
  //     async (data: any) => {
  //       setMarkingValue({
  //         correct: data?.assessmentReport?.assessmentAutoMarking?.correct || 0,
  //         partiallyCorrect:
  //           data?.assessmentReport?.assessmentAutoMarking?.partiallyCorrect || 0,
  //         incorrect:
  //           data?.assessmentReport?.assessmentAutoMarking?.incorrect || 0,
  //         notAnswered:
  //           data?.assessmentReport?.assessmentAutoMarking?.notAnswered || 0,
  //       })
  //     },
  //     () => {
  //       notify({
  //         alertHeader: 'Something went wrong',
  //         alertBody: '',
  //         status: 'Error',
  //       })
  //     },
  //   )

  const {
    mutate: getRealtimeAssessmentRating,
    isLoading: isRealtimeAssessmentRatingLoading,
  } = useGetRealtimeAssessmentRating()

  const fetchRealtimeRatingData = async (updatedJsonSchema) => {
    await getRealtimeAssessmentRating(
      {
        candidateId: candidateId,
        assessmentId: templateId,
        jsonSchema: updatedJsonSchema,
        assessmentResponseId: reportViewData?.assessmentResponseId,
        candidateStage: candidateStage,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setMarkingValue({
            correct: successData?.assessmentAutoMarkingData?.correct || 0,
            partiallyCorrect:
              successData?.assessmentAutoMarkingData?.partiallyCorrect || 0,
            incorrect: successData?.assessmentAutoMarkingData?.incorrect || 0,
            notAnswered:
              successData?.assessmentAutoMarkingData?.notAnswered || 0,
          })
          setTotalMarks(successData?.assessmentAutoMarkingData?.totalMarks)
        },
        onError: ({ response: { data: errData } }: any) => {
          if (
            errData &&
            (errData?.messageTitle as string).includes(
              'Test SMS count Exceeded',
            )
          ) {
            Toast({
              alertHeader: errData?.messageTitle,
              alertBody: errData?.message,
              status: 'Warning',
            })
          } else {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }
  return (
    <>
      <Modal
        headerTitle={`Assessment`}
        customWidth='1125px'
        isActive={isModalActive}
        //   primaryButtonTheme={'cobalt'}
        //   primaryButtonTitle=''
        //   footerButtonAlignment='right'
        isFooterShow={false}
        size='lg'
        onClickSecondaryBtn={() => setIsModalActive(false)}
        onHeaderCloseButtonClick={() => setIsModalActive(false)}
        onOverlayClick={() => setIsModalActive(false)}
        secondaryButtonTitle='Close'>
        {(assessmentReportViewLoading || assessmentReportViewFetching) && (
          <div className='h-[400px] w-full'></div>
        )}

        {reportViewData && (
          <div className='flex flex-col w-full justify-start max-h-[720px] overflow-y-auto bg-[#F9FAFB]'>
            <div className='p-8 w-full flex justify-between gap-4 '>
              <div className='w-[45%]'>
                <AssessmentCompletedDetails
                  setShowDownlaodAssessmentReportModal={
                    setShowDownlaodAssessmentReportModal
                  }
                  isReport={false}
                  reportViewData={reportViewData}
                />
              </div>
              <div className='w-[55%] gap-4 flex flex-col'>
                <AutoMarkingProgress
                  values={markingValue}
                  isReport={false}
                  totalMarks={totalMarks}
                />
                <CandidateStage
                  reportViewData={reportViewData}
                  onChange={(stage: string) => setCandidateStage(stage)}
                />
              </div>
            </div>

            <div className='p-8 pt-0 w-full flex justify-between gap-4 bg-[#F9FAFB]'>
              <div className='w-[45%] h-full'>
                <AntiCheatinMonitor
                  reportViewData={reportViewData}
                  isReport={false}
                />
              </div>
              <div className='w-[55%] gap-4 flex flex-col'>
                <RatingTable
                  reportViewData={reportViewData}
                  setJsonSchema={setJsonSchema}
                  jsonSchema={jsonSchema}
                  fetchRealtimeRatingData={fetchRealtimeRatingData}
                  isRealtimeAssessmentRatingLoading={
                    isRealtimeAssessmentRatingLoading
                  }
                />
              </div>
            </div>
          </div>
        )}

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button
              onClick={() => setIsModalActive(false)}
              textTheme='blue'
              theme='white'>
              Cancel
            </Button>
            <Button
              onClick={() => handleUpdate()}
              isLoading={questionsUpdateLoading}
              isDisabled={questionsUpdateLoading}
              className='ml-2'
              theme='cobalt'>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {showDownlaodAssessmentReportModal && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={showDownlaodAssessmentReportModal}
          setIsModalActive={setShowDownlaodAssessmentReportModal}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
        />
      )}
    </>
  )
}
